import { useMachine } from '@xstate/react';
import { useCallback, useEffect, useMemo } from 'react';
import {
  BeginInterval,
  GearQuestion,
  useParticipantState,
  useSocket,
  useTimer,
} from 'src/Context';
import { GlobalSocketEmitEvents } from 'src/Context/SocketTypes';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { SurveyView } from 'src/GlobalComponents/Overlay/SurveyOverlay';
import { capitalize } from 'src/Helpers';

import { Prompt } from '../Prompt';
import './GearMachine.css';
import { ButtonController, GearFeedback, ImageViewer } from './Components';
import { gearQuestionMachine } from './GearQuestionMachine';

interface IAnswerProblem {
  beginInterval: BeginInterval;
  isTeacher: boolean;
  prompt: string;
}

interface IReadyToAnswer {
  beginInterval: BeginInterval;
  prompt: string;
  isTeacher: boolean;
}

interface IReadProblemView {
  beginInterval: BeginInterval;
  isTeacher: boolean;
  prompt: string;
  showFirstTimeSurveyPrompt: boolean;
}

interface IGearQuestionViews {
  machineId: string;
  initialCount: number;
  questions: GearQuestion[];
}

enum SocketOnEvents {
  PROCEED_GEAR_QUESTION_MACHINE = 'PROCEED GEAR_QUESTION_MACHINE',
  SELECTED_ANSWER = 'SELECTED ANSWER',
}

export function GearQuestionViews({
  machineId,
  questions,
  initialCount,
}: IGearQuestionViews): JSX.Element {
  const timer = useTimer();
  const socket = useSocket();
  const { isTeacher } = useParticipantState();
  const [state, send] = useMachine(gearQuestionMachine(machineId));
  const currentQuestionIndex: number = state.context.currentQuestionIndex;

  const questionCount = useMemo(() => initialCount + currentQuestionIndex + 1, [
    currentQuestionIndex,
    initialCount,
  ]);

  const currentQuestion = useMemo(() => questions[currentQuestionIndex], [
    currentQuestionIndex,
    questions,
  ]);

  const showFirstTimeSurveyPrompt = useMemo(() => questionCount === 1, [
    questionCount,
  ]);

  const beginInterval = useCallback(
    (intervalName: string, keepInterval: boolean) =>
      timer.beginInterval(`${intervalName} [${questionCount}]`, keepInterval),
    [questionCount, timer],
  );

  const getInterval = useCallback(
    (intervalName: string) =>
      timer.getInterval(`${intervalName} [${questionCount}]`),
    [questionCount, timer],
  );

  const sendFeedbackData = useCallback(
    (answer: string) => {
      socket.emit(GlobalSocketEmitEvents.RESPONSE_DATA, {
        type: 'GEAR',
        responseData: {
          isCorrect: answer === currentQuestion!.correctAnswer,
          count: currentQuestion.gearCount,
          type: currentQuestion.type,
          gearQuestionIndex: `Gear ${questionCount}`,
          atClientTime: Date.now(),
        },
      });
    },
    [currentQuestion, questionCount, socket],
  );

  useEffect(() => {
    socket.on(SocketOnEvents.PROCEED_GEAR_QUESTION_MACHINE, () =>
      send('PROCEED_GEAR_QUESTION_MACHINE'),
    );
    socket.on(SocketOnEvents.SELECTED_ANSWER, (answer: string) => {
      send({ type: 'PROCEED_GEAR_QUESTION_MACHINE', payload: answer });
      sendFeedbackData(answer);
    });

    return () => {
      socket.off(SocketOnEvents.PROCEED_GEAR_QUESTION_MACHINE);
      socket.off(SocketOnEvents.SELECTED_ANSWER);
    };
  }, [send, sendFeedbackData, socket]);

  if (!currentQuestion) {
    return <></>;
  }

  return (
    <Container>
      <HeaderContainer>
        <ImageViewer
          fileName={`${currentQuestion.type}/${currentQuestion.imageUrl}`}
          alt={`${currentQuestion.gearCount} gear image`}
          className="question-image"
        />
      </HeaderContainer>
      <ContentContainer>
        {
          {
            readProblem: (
              <ReadProblemView
                beginInterval={beginInterval}
                isTeacher={isTeacher}
                prompt={currentQuestion!.prompt}
                showFirstTimeSurveyPrompt={showFirstTimeSurveyPrompt}
              />
            ),
            readyToAnswer: (
              <ReadyToAnswer
                beginInterval={beginInterval}
                isTeacher={isTeacher}
                prompt={currentQuestion.prompt}
              />
            ),
            answerProblem: (
              <AnswerProblem
                beginInterval={beginInterval}
                isTeacher={isTeacher}
                prompt={currentQuestion.prompt}
              />
            ),
            survey: (
              <SurveyView
                emitTo={'GEAR_QUESTION_MACHINE'}
                section="gears"
                problem={questionCount}
              />
            ),
            feedback: (
              <GearFeedback
                beginInterval={beginInterval}
                isTeacher={isTeacher}
                answer={state.context.studentAnswer}
                gearCount={currentQuestion.gearCount}
                correctAnswer={currentQuestion.correctAnswer}
              />
            ),
          }[state.value as string]
        }
      </ContentContainer>
      <FooterContainer>
        <ButtonController
          key={questionCount}
          getInterval={getInterval}
          gearType={currentQuestion.type}
          state={state.value as string}
          isTeacher={isTeacher}
          isLastQuestion={!questions[currentQuestionIndex + 1]}
        />
      </FooterContainer>
    </Container>
  );
}

function ReadProblemView({
  beginInterval,
  isTeacher,
  prompt,
}: IReadProblemView): JSX.Element {
  useEffect(() => {
    beginInterval('Problem', isTeacher);
  }, [beginInterval, isTeacher]);
  return (
    <>
      <Prompt
        marginTop={40}
        alignment="center"
        message={isTeacher ? '[Read aloud]' : '[Listen]'}
      />
      {!isTeacher && (
        <Prompt marginTop={40} alignment="center" message={"[Don't Respond]"} />
      )}
      {isTeacher && (
        <>
          <TextContainer marginTop={20}>{prompt}</TextContainer>
          <Prompt marginTop={20} alignment="center" message="[now click]" />
        </>
      )}
    </>
  );
}

function ReadyToAnswer({
  beginInterval,
  isTeacher,
  prompt,
}: IReadyToAnswer): JSX.Element {
  useEffect(() => {
    beginInterval('RT', !isTeacher);
  }, [beginInterval, isTeacher]);

  return (
    <Prompt
      alignment="center"
      marginTop={80}
      message={
        isTeacher ? (
          '[Wait for student response]'
        ) : (
          <TextContainer fontSize={1} alignment="center">
            [click <u>Before</u> responding]
          </TextContainer>
        )
      }
    />
  );
}

function AnswerProblem({
  beginInterval,
  isTeacher,
  prompt,
}: IAnswerProblem): JSX.Element {
  useEffect(() => {
    beginInterval('Response', isTeacher);
  });
  const splitText = prompt.split(',');
  const studentText = capitalize(splitText[2].trim());

  return (
    <>
      {!isTeacher && (
        <TextContainer marginTop={50} alignment="center">
          {studentText}
        </TextContainer>
      )}
      <br />
      <br />
      <Prompt
        alignment="center"
        message={
          isTeacher ? "[Click student's response]" : '[Respond out loud]'
        }
      />
    </>
  );
}
