import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AdminContainer } from './Containers';
import { AdminContext, Admin, AlertProvider, SocketProvider } from './Context';
import { SurveyRoot } from './Containers/Participant/SurveyRoot';
import { AlertBanner } from './GlobalComponents';

function App() {
  const adminContext = React.useState<Admin | null>(null);
  return (
    <AlertProvider>
      <AlertBanner />
      <Switch>
        <Route path="/survey">
          <SocketProvider>
            <SurveyRoot />
          </SocketProvider>
        </Route>
        <Route path="/admin">
          <AdminContext.Provider value={adminContext}>
            <AdminContainer />
          </AdminContext.Provider>
        </Route>
        <Redirect to={'/survey'} />
      </Switch>
    </AlertProvider>
  );
}

export default App;
