import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'src/Containers/Participant/QuestionContainer/Components';
import { Container, ContentContainer, FooterContainer } from '..';
import { Button } from '../Button';

interface ContentConfig {
  onClick: () => void;
  showButton: boolean;
}

interface ITextBrowser {
  showPrompt?: boolean;
  content: ReactNode[];
  finalMessage?: string;
  hideFinalButton?: boolean;
  onRender?: () => void;
  onFinalClick?: () => void;
  override?: ContentConfig[];
}

export const TextBrowser = ({
  content,
  onFinalClick,
  finalMessage,
  hideFinalButton,
  onRender,
  override,
  showPrompt,
}: ITextBrowser) => {
  useEffect(() => {
    onRender && onRender();
  }, [onRender]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [passageIndex, setPassageIndex] = useState<number>(0);
  const [showPleaseWaitMessage, setShowPleaseWaitMessage] = useState(false);

  if (override && override.length !== content.length) {
    throw new Error('Config mismatch');
  }

  const isFinalPassage = !content[passageIndex + 1];

  const onClick = () => {
    if (override) {
      override[passageIndex].onClick();
    }

    if (isFinalPassage) {
      setIsDisabled(true);
      setShowPleaseWaitMessage(true);
      onFinalClick && onFinalClick();
      return;
    }
    setPassageIndex((p) => p + 1);
  };

  const buttonText = useMemo(() => {
    if (showPleaseWaitMessage) {
      return 'Please Wait...';
    }
    return !isFinalPassage ? 'Done Reading' : finalMessage || 'Ready to Begin';
  }, [finalMessage, isFinalPassage, showPleaseWaitMessage]);

  const overrideShowPrompt = useMemo(() => {
    let showPrompt = true;

    if (override) {
      showPrompt = override[passageIndex].showButton;
    }
    return showPrompt;
  }, [override, passageIndex]);

  const shouldShowPrompt = !isDisabled && showPrompt && overrideShowPrompt;

  return (
    <Container headerType="small">
      <ContentContainer>
        <>
          {content[passageIndex]}
          {shouldShowPrompt && (
            <Prompt marginTop={20} message="[now click]" alignment="center" />
          )}
        </>
      </ContentContainer>
      <FooterContainer>
        {override ? (
          <>
            {override[passageIndex].showButton && (
              <Button
                isDisabled={isDisabled}
                variant="outline-primary"
                onClick={onClick}
              >
                {buttonText}
              </Button>
            )}
          </>
        ) : (
          <>
            {!hideFinalButton && (
              <Button
                isDisabled={isDisabled}
                variant="outline-primary"
                onClick={onClick}
              >
                {buttonText}
              </Button>
            )}
          </>
        )}
      </FooterContainer>
    </Container>
  );
};
