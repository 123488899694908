import { assign, Machine } from 'xstate';

export const textQuestionMachine = Machine<
  { currentPassage: number },
  {
    type: 'PROCEED_TEXT_QUESTION_MACHINE';
  }
>(
  {
    id: 'text-question-machine',
    context: {
      currentPassage: 0,
    },
    initial: 'teacherRead',
    states: {
      teacherRead: {
        on: {
          PROCEED_TEXT_QUESTION_MACHINE: {
            target: 'studentRead',
          },
        },
      },
      studentRead: {
        on: {
          PROCEED_TEXT_QUESTION_MACHINE: {
            target: 'survey',
          },
        },
      },
      survey: {
        on: {
          PROCEED_TEXT_QUESTION_MACHINE: {
            target: 'thoughtQuestions',
          },
        },
      },
      thoughtQuestions: {
        on: {
          PROCEED_TEXT_QUESTION_MACHINE: {
            target: 'teacherRead',
          },
        },
        exit: 'incrementCurrentPassage',
      },
      // survey2: {
      //   on: {
      //     PROCEED_TEXT_QUESTION_MACHINE: {
      //       target: "thoughtQuestions",
      //     },
      //   },
      // },
    },
  },
  {
    actions: {
      incrementCurrentPassage: assign((context) => ({
        currentPassage: context.currentPassage + 1,
      })),
    },
  },
);
