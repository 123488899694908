import * as React from 'react';
import Alert from 'react-bootstrap/Alert';
import {
  useAlertMessage,
  useUpdateAlertMessage,
} from '../../Context/AlertContext';

import { Container } from './AlertBanner.styled';

export interface IAlertBanner {
  message: string;
  type?: 'danger' | 'success';
}

export function AlertBanner(): JSX.Element {
  const alert = useAlertMessage();
  const setAlert = useUpdateAlertMessage();

  React.useEffect(() => {
    const cancelAlert = () =>
      setTimeout(() => setAlert({} as IAlertBanner), 10000);
    if (alert.message) {
      cancelAlert();
    }
  }, [alert, setAlert]);

  return alert.message ? (
    <Container>
      <Alert variant={alert.type || 'danger'}>{alert.message}</Alert>
    </Container>
  ) : (
    <></>
  );
}
