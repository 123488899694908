import * as React from 'react';
import { useHistory } from 'react-router-dom';

export type ExperimentSections = 'gears' | 'text' | 'science';
export interface Participant {
  participantId: string;
  isTeacher: boolean;
  videoEnabled: boolean;
  useMedia: boolean;
  sections: ExperimentSections[];
  peerId: string;
}

const defaultParticipant = {
  participantId: '',
  isTeacher: false,
  videoEnabled: false,
  useMedia: false,
  sections: [],
  peerId: '',
};

const ParticipantUpdateContext = React.createContext<
  React.Dispatch<React.SetStateAction<Participant>>
>(() => null);

const ParticipantContext = React.createContext<Participant>(defaultParticipant);

export const ParticipantProvider = (props: { children: React.ReactNode }) => {
  const [participant, setParticipant] = React.useState<Participant>(
    defaultParticipant,
  );

  return (
    <ParticipantUpdateContext.Provider value={setParticipant}>
      <ParticipantContext.Provider value={participant}>
        {props.children}
      </ParticipantContext.Provider>
    </ParticipantUpdateContext.Provider>
  );
};

export function useParticipantState() {
  const history = useHistory();
  const participant = React.useContext(ParticipantContext);
  if (!participant.participantId) {
    history.push('/survey');
  }
  return participant;
}

export function useUpdateParticipantState() {
  const setParticipant = React.useContext(ParticipantUpdateContext);

  if (!setParticipant) {
    throw new Error('No participant setter');
  }
  return setParticipant;
}
