import * as React from 'react';
import { io, Socket } from 'socket.io-client';

import { API } from '../Services/api';
import { GlobalSocketEmitEvents } from './SocketTypes';

interface MCResponse {
  participantId: string;
  question: string;
  answer: string;
}

interface GearResponse {
  questionId: string;
  answer: string;
}

interface SurveyResponse {
  participantId: string;
  response: string;
  surveyType: string;
}

interface Timestamp {
  participantId: string;
  eventName: string;
  atClientTime: number;
  interval: number;
}

type ResponseData = GearResponse | SurveyResponse | MCResponse;
type ResponseType = 'MC' | 'GEAR' | 'SURVEY';
export type Machine =
  | 'MAIN'
  | 'DEMO_MACHINE'
  | 'DEMO_QUESTION_MACHINE'
  | 'GEAR_MACHINE'
  | 'SCIENCE_MACHINE'
  | 'TEXT_MACHINE'
  | 'GEAR_QUESTION_MACHINE'
  | 'TEXT_QUESTION_MACHINE'
  | 'SCIENCE_QUESTION_MACHINE'
  | 'READING'; //used for proceeding text passage sections

interface DebugTypes {
  event: boolean;
  on: boolean;
  emit: boolean;
  system: boolean;
  creation: boolean;
}

const defaultDebugSettings = {
  event: true,
  on: true,
  emit: true,
  system: true,
  creation: false,
};

class AppSocket {
  private socket: Socket | null;
  enabled: boolean;

  private debug: boolean;
  private debugTypes: DebugTypes;
  private reconnectionAttempts: number;
  private eventHandlers: AppSocketEventHandlers;

  constructor(eventHandlers: AppSocketEventHandlers) {
    this.socket = null;
    this.enabled = false;
    this.debug = false;
    this.debugTypes = defaultDebugSettings;
    this.reconnectionAttempts = 3;
    this.eventHandlers = eventHandlers;
  }

  public startSocket(participantId?: string): void {
    const socket = this._createSocket(participantId);
    this._addListeners(socket);
    this.socket = socket;
    this.enabled = true;
  }

  public stopSocket(): void {
    this.enabled = false;
    this.socket?.offAny();
    this.socket?.close();
  }

  public async emit(
    event: string,
    arg?: any,
    usePromise = false,
  ): Promise<void> {
    this._logger('emit', ["'emit' called with: ", { event, arg }]);

    if (usePromise) {
      await new Promise((resolve) => {
        this.socket?.emit(event, arg, (ack: boolean) => {
          resolve(ack);
        });
      });
    } else {
      this.socket?.emit(event, arg);
    }
  }

  /**
   *
   * @param event Name of event to act upon.
   * @param cb
   * @param allowMultipleListeners By default only one listener can exist for an event
   */
  public on(
    event: string,
    cb: (...args: any[]) => void,
    allowMultipleListeners = false,
  ): void {
    if (
      this.socket &&
      (!this.socket.hasListeners(event) || allowMultipleListeners)
    ) {
      this.socket.on(event, cb);
      this._logger('on', ["'on' called with: ", event]);
    }
  }

  public off(event: string, listener?: Function): void {
    if (this.socket && this.socket.hasListeners(event)) {
      this.socket.off(event, listener);
    }
  }

  private _createSocket(participantId?: string): Socket {
    const socketInstance = io(API.SOCKET_SERVER_ADDRESS, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: this.reconnectionAttempts,
      transports: ['websocket'],
      agent: false,
      upgrade: false,
      rejectUnauthorized: true,
      query: { participant: participantId || '' },
    });

    return socketInstance;
  }

  private _addListeners(socket: Socket) {
    socket.on('connect', () => {
      this.eventHandlers.onConnect();
      this._logger('event', 'onConnect');
    });

    socket.on('connect_error', (err: any) => {
      this._logger('event', ['connection Error:', err]);
    });

    socket.on('disconnect', (reason) => {
      this._logger('event', `socket disconnected because: ${reason}`);
    });

    socket.io.on('reconnect_attempt', (attempt) => {
      if (attempt === this.reconnectionAttempts) {
        this.eventHandlers.onReconnecting();
      }
    });

    socket.io.on('reconnect', (attempt) => {});
  }

  private _logger(event: keyof DebugTypes, datum: any[] | any) {
    if (this.debug && this.debugTypes[event]) {
      console.log(event, datum);
    }
  }
}

export interface AppSocketEventHandlers {
  onConnect: () => void;
  onReconnecting: () => void;
}
export class AppSocketManager implements AppSocketEventHandlers {
  private socketInstance: AppSocket | null = null;
  private participantId: string | undefined;
  private static instance: AppSocketManager;
  public onConnect: () => void = () => null;
  private attempts: number = 0;
  private debug = false;

  public static getInstance() {
    if (!AppSocketManager.instance) {
      AppSocketManager.instance = new AppSocketManager();
    }
    return AppSocketManager.instance;
  }

  public initSocket() {
    this.socketInstance = new AppSocket(this);
    this.startSocket();
  }

  public onReconnecting: () => void = () => {
    if (this.attempts === 3) {
      this.stopSocket();
    } else {
      this._destoySocket();
      this.socketInstance = null;
      this.initSocket();
      this.attempts++;
    }
  };

  public on(
    event: string,
    cb: (...args: any[]) => void,
    allowMultipleListeners = false,
  ) {
    this.socketInstance &&
      this.socketInstance.on(event, cb, allowMultipleListeners);
  }

  public startSocket(): void {
    if (this.socketInstance && !this.socketInstance.enabled) {
      this.socketInstance.startSocket(this.participantId);
    }
  }

  public stopSocket(): void {
    this._destoySocket();
  }

  public off(event: string, listener?: Function): void {
    this.socketInstance?.off(event, listener);
  }

  public emit(event: string, args?: any, usePromise = false): void {
    this.socketInstance?.emit(event, args, usePromise);
  }

  public emitProceed(machine: Machine, id?: string) {
    if (id && this.debug) {
      console.log(`${id} triggered unguarded proceed`);
    }
    this.socketInstance?.emit(GlobalSocketEmitEvents.PROCEED, {
      machine,
      caller: id,
    });
  }
  public emitGuardedProceed(machine: Machine, id?: string) {
    if (id && this.debug) {
      console.log(`${id} triggered guarded proceed`);
    }
    this.socketInstance?.emit(GlobalSocketEmitEvents.GUARDED_PROCEED, machine);
  }

  public async emitIntervalData(timestamp: Timestamp) {
    await this.socketInstance?.emit(
      GlobalSocketEmitEvents.TIMESTAMP,
      timestamp,
      true,
    );
  }

  public emitResponseData(
    responseType: ResponseType,
    participantId: string,
    data: ResponseData,
  ) {
    this.socketInstance?.emit('RESPONSE_DATA', {
      responseType,
      participantId,
      data,
    });
  }

  public setParticipantId(participantId: string) {
    this.participantId = participantId;
  }

  public connectedLogger = (
    message: string,
    {
      additionalInfo,
      level = 'info',
    }: { additionalInfo?: any; level?: string },
  ) => {
    this.socketInstance?.emit('LOG', {
      additionalInfo,
      level,
      message,
      participant: this.participantId,
    });
  };

  private _destoySocket = () => {
    this.socketInstance?.stopSocket();
    this.socketInstance = null;
  };
}

const SocketContext = React.createContext({} as AppSocketManager);

export const SocketProvider = React.memo(
  (props: { children: React.ReactNode }): JSX.Element => {
    const socket = AppSocketManager.getInstance();

    return (
      <SocketContext.Provider value={socket}>
        {props.children}
      </SocketContext.Provider>
    );
  },
);

export const useSocket = () => {
  const socket = React.useContext(SocketContext);

  if (!socket) {
    throw new Error('No socket set');
  }
  return socket;
};
