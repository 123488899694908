import { useEffect, useState } from 'react';

export const useImage = (fileName: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | Error>(null);
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../assets/${fileName}`);
        setImage(response.default);
        setError(null);
      } catch (err: unknown) {
        setError(err as Error);
      }
      setLoading(false);
    };

    fetchImage();
  }, [fileName]);

  return {
    loading,
    error,
    image,
  };
};
