import { useMachine } from '@xstate/react';
import * as React from 'react';
import Button from 'react-bootstrap/Button';

import {
  QuestionContext,
  TextQuestion,
  useParticipantState,
  useSocket,
  useTimer,
} from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { data } from 'src/Components/mockdata';

import { SurveyViewWithMC } from 'src/GlobalComponents/Overlay/MCSurveyOverlay';
import { ThoughtQuestions } from './Components';
import { Prompt } from '../Prompt';
import { textQuestionMachine } from './TextQuestionMachine';

enum SocketOnEvents {
  PROCEED_TEXT_QUESTION_MACHINE = 'PROCEED TEXT_QUESTION_MACHINE',
  PROCEED_READING = 'PROCEED READING',
}

export function TextQuestionViews(): JSX.Element {
  const socket = useSocket();
  const [state, send] = useMachine(textQuestionMachine);
  const textQuestions =
    React.useContext(QuestionContext)?.textQuestions || data.textQuestions;

  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);

  const { currentPassage } = state.context;

  const setNextQuestion = () => {
    if (!!textQuestions[currentPassage + 1]) {
      socket.emitProceed('TEXT_QUESTION_MACHINE');
    } else {
      socket.emitProceed('TEXT_MACHINE');
    }
  };

  React.useEffect(() => {
    if (buttonDisabled) {
      setTimeout(() => setButtonDisabled(false), 1000);
    }
  }, [buttonDisabled]);

  React.useEffect(() => {
    socket.on(SocketOnEvents.PROCEED_TEXT_QUESTION_MACHINE, () => {
      send('PROCEED_TEXT_QUESTION_MACHINE');
    });
    return () => {
      socket.off(SocketOnEvents.PROCEED_TEXT_QUESTION_MACHINE);
    };
  }, [send, socket]);

  if (state.matches('teacherRead')) {
    return (
      <ReaderContainer
        passageIndex={currentPassage + 1}
        readerIsTeacher
        textData={() => textQuestions[currentPassage]}
      />
    );
  }

  if (state.matches('studentRead')) {
    return (
      <ReaderContainer
        passageIndex={currentPassage + 1}
        textData={() => textQuestions[currentPassage]}
      />
    );
  }

  if (state.matches('survey')) {
    return (
      <SurveyViewWithMC
        mcQuestions={textQuestions[currentPassage].questions}
        questionCount={currentPassage + 1}
      />
    );
  }

  if (state.matches('thoughtQuestions')) {
    return (
      <ThoughtQuestions
        nextPassage={setNextQuestion}
        passageIndex={currentPassage}
      />
    );
  }

  return <div>Loading</div>;
}

interface IReaderContainer {
  passageIndex: number;
  readerIsTeacher?: boolean;
  textData: () => TextQuestion;
}

const ReaderContainer = ({
  passageIndex,
  readerIsTeacher,
  textData,
}: IReaderContainer) => {
  const socket = useSocket();
  const timer = useTimer();
  const { isTeacher } = useParticipantState();

  const [textSectionIndex, setTextSectionIndex] = React.useState<number>(0);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);

  const currentSection = textData().sections[textSectionIndex];
  const nextSection = textData().sections[textSectionIndex + 1];

  const intervalName = React.useMemo(() => `Text Reading ${passageIndex}`, [
    passageIndex,
  ]);

  React.useEffect(() => {
    setTextSectionIndex(0);
  }, [readerIsTeacher]);

  React.useEffect(() => {
    if (readerIsTeacher && isTeacher) {
      timer.beginInterval(intervalName);
    } else if (!readerIsTeacher && !isTeacher) {
      timer.beginInterval(intervalName);
    }

    socket.on(SocketOnEvents.PROCEED_READING, () =>
      setTextSectionIndex((i) => i + 1),
    );

    return () => {
      socket.off(SocketOnEvents.PROCEED_READING);
    };
  }, [timer, readerIsTeacher, isTeacher, socket, intervalName]);

  React.useEffect(() => {
    if (buttonDisabled) {
      const buttonTimer = setTimeout(() => setButtonDisabled(false), 1000);
      return () => clearTimeout(buttonTimer);
    }
  }, [buttonDisabled]);

  const handleProceed = async () => {
    setButtonDisabled(true);
    if (!!nextSection) {
      socket.emitProceed('READING');
    } else {
      await timer.getInterval(`Text Reading ${passageIndex}`);
      socket.emitProceed('TEXT_QUESTION_MACHINE');
    }
  };

  return (
    <Container headerType="small">
      <HeaderContainer>
        <Prompt alignment="center" message={promptText()} />
      </HeaderContainer>
      <ContentContainer verticalCenter>
        <TextContainer fontSize={1.2} marginTop={20}>
          {currentSection}
        </TextContainer>
      </ContentContainer>
      <FooterContainer>
        {readerIsTeacher && isTeacher && (
          <Button disabled={buttonDisabled} onClick={handleProceed}>
            Continue
          </Button>
        )}
        {!readerIsTeacher && !isTeacher && (
          <Button disabled={buttonDisabled} onClick={handleProceed}>
            Continue
          </Button>
        )}
      </FooterContainer>
    </Container>
  );

  function promptText() {
    if (isTeacher) {
      return readerIsTeacher ? '[Read aloud]' : '[Listen]';
    } else {
      return readerIsTeacher ? '[Listen]' : '[Read aloud]';
    }
  }
};
