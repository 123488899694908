import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket, useTimer } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';

import { Prompt } from '../../../Prompt';

export function TextBriefing(): JSX.Element {
  const { isTeacher } = useParticipantState();
  const timer = useTimer();
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const [buttonText, setButtonText] = React.useState('Begin!');
  const socket = useSocket();

  React.useEffect(() => {
    timer.beginInterval('Teaching TX');
  }, [timer]);

  const handleClick = async () => {
    await timer.getInterval('Teaching TX');
    setIsClicked(true);
    socket.emitGuardedProceed('TEXT_MACHINE');
    setButtonText('Please Wait...');
  };

  return (
    <Container headerType="small">
      <HeaderContainer>
        <Prompt
          alignment="center"
          message={isTeacher ? '[Read Aloud]' : '[Listen]'}
        />
      </HeaderContainer>
      <ContentContainer>{isTeacher && AboutText()}</ContentContainer>
      <FooterContainer>
        <Button
          variant="outline-primary"
          onClick={handleClick}
          disabled={isClicked}
        >
          {buttonText}
        </Button>
      </FooterContainer>
    </Container>
  );
}

const AboutText = (): JSX.Element => {
  return (
    <>
      <TextContainer marginTop={25} fontSize={1.3}>
        For this task, I will be reading texts for you to actively listen to,
        and then you will read the text back to me. Afterwards, we will each
        answer several questions about our own state and what we remember from
        the text.
      </TextContainer>
      <TextContainer marginTop={15} fontSize={1.3}>
        Then, I will ask you questions about each text to understand better what
        you learned from it. We will read 4 texts in total. The first and second
        will be connected, as will the third and the fourth. After the second
        and the fourth text, I will ask a deeper question about both together.
      </TextContainer>
      <TextContainer marginTop={15} fontSize={1.3}>
        Do you have any questions before we begin?
      </TextContainer>
    </>
  );
};
