import { assign, Machine } from 'xstate';

export type DemoEvent = {
  type: 'PROCEED_DEMO_QUESTION_MACHINE';
  payload?: string;
};

interface DemoQuestionMachine {
  currentQuestionIndex: number;
  studentAnswer: string;
}

export const demoQuestionMachine = () =>
  Machine<DemoQuestionMachine, DemoEvent>(
    {
      id: 'demo-question-machine',
      initial: 'readProblem',
      context: {
        currentQuestionIndex: 0,
        studentAnswer: '',
      },
      states: {
        readProblem: {
          on: {
            PROCEED_DEMO_QUESTION_MACHINE: {
              target: 'readyToAnswer',
            },
          },
        },
        readyToAnswer: {
          on: {
            PROCEED_DEMO_QUESTION_MACHINE: {
              target: 'answerProblem',
            },
          },
        },
        answerProblem: {
          on: {
            PROCEED_DEMO_QUESTION_MACHINE: {
              target: 'survey',
              actions: ['updateStudentAnswer'],
            },
          },
        },
        survey: {
          on: {
            PROCEED_DEMO_QUESTION_MACHINE: {
              target: 'feedback',
            },
          },
        },
        feedback: {
          on: {
            PROCEED_DEMO_QUESTION_MACHINE: {
              target: 'readProblem',
              actions: ['incrementIndex'],
            },
          },
        },
      },
    },
    {
      actions: {
        incrementIndex: assign((context) => ({
          ...context,
          currentQuestionIndex: context.currentQuestionIndex + 1,
        })),
        updateStudentAnswer: assign((context, e) => ({
          ...context,
          studentAnswer: e.payload,
        })),
      },
    },
  );
