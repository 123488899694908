import * as React from 'react';

export interface Admin {
  user: string;
}

type AdminState = Admin | null;
type StateAdminContext = [
  AdminState,
  React.Dispatch<React.SetStateAction<AdminState>>,
];

export const AdminContext = React.createContext<StateAdminContext>([
  null,
  () => null,
]);
