import { ReactNode, useEffect, useState } from 'react';
import BootstrapButton from 'react-bootstrap/Button';

interface IButton {
  onClick: () => void;
  disabledTimeout?: number;
  variant?: string;
  children: ReactNode;
  isDisabled?: boolean;
}

export const Button = ({
  onClick,
  disabledTimeout,
  children,
  variant,
  isDisabled,
}: IButton) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (disabled) {
      let timer = setTimeout(() => setDisabled(false), disabledTimeout || 1000);
      return () => clearInterval(timer);
    }
  }, [disabled, disabledTimeout]);

  const handleClick = () => {
    setDisabled(true);
    onClick();
  };

  return (
    <BootstrapButton
      variant={variant}
      onClick={handleClick}
      disabled={isDisabled || disabled}
    >
      {children}
    </BootstrapButton>
  );
};
