import Button from 'react-bootstrap/Button';

import { useSocket } from 'src/Context';

import { AnswerButtonGroup } from '../AnswerButtonGroup';

interface IButtonController {
  isTeacher: boolean;
  state: string;
  isLastQuestion: boolean;
  buttonsDisabled: boolean;
  setButtonsDisabled: () => void;
}

export function ButtonController({
  isTeacher,
  state,
  buttonsDisabled,
  isLastQuestion,
  setButtonsDisabled,
}: IButtonController): JSX.Element | null {
  const buttonText = () => {
    switch (state) {
      case 'readProblem':
        return 'Done Reading';
      case 'readyToAnswer':
        return 'CLICK then Respond';
      case 'feedback':
        return 'Next Question';
      default:
        return null;
    }
  };

  const teacherViews = ['readProblem', 'feedback'];
  const teacherCanViewButton = isTeacher && teacherViews.includes(state);
  const studentCanViewButton = !isTeacher && state === 'readyToAnswer';

  const socket = useSocket();

  const handleClick = () => {
    socket.emitProceed(
      isLastQuestion && state === 'feedback'
        ? 'DEMO_MACHINE'
        : 'DEMO_QUESTION_MACHINE',
    );
    if (!(isLastQuestion && state === 'feedback')) {
      setButtonsDisabled();
    }
  };

  const handleStudentClick = () => {
    socket.emitProceed('DEMO_QUESTION_MACHINE');
  };

  if (teacherCanViewButton) {
    return (
      <Button disabled={buttonsDisabled} onClick={handleClick}>
        {isLastQuestion && state === 'feedback' ? 'Continue' : buttonText()}
      </Button>
    );
  }

  if (studentCanViewButton) {
    return <Button onClick={handleStudentClick}>{buttonText()}</Button>;
  }

  if (isTeacher && state === 'answerProblem') {
    return <AnswerButtonGroup />;
  }

  return null;
}
