import React from 'react';

import { useHistory, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { AdminContext, SessionContext, SessionData } from 'src/Context';
import { capitalize } from 'src/Helpers';

import {
  AdminDashboardContainer,
  Container,
  Header,
  HeaderButton,
  HeaderText,
} from './AdminDashboard.styled';
import { Session, SessionsTable } from '../SessionsContainer';
import TokenService from 'src/Services/TokenService';

export function AdminDashboard(): JSX.Element {
  const admin = React.useContext(AdminContext)[0];
  const sessionState = React.useState<SessionData | null>(null);

  const history = useHistory();
  const match = useRouteMatch();

  const navToSessions = () => {
    history.push('/admin/sessions');
  };

  const logout = () => {
    TokenService.clearToken();
    history.push('/admin/login');
  };

  return (
    <Container>
      <Header>
        <HeaderButton onClick={navToSessions}>Sessions</HeaderButton>
        <HeaderText>Welcome Back, {capitalize(admin!.user)}!</HeaderText>
        <HeaderButton onClick={logout}>Log Out</HeaderButton>
      </Header>
      <AdminDashboardContainer>
        <SessionContext.Provider value={sessionState}>
          <Switch>
            <Route exact path={`${match.path}`}>
              <SessionsTable />
            </Route>
            <Route exact path={`${match.path}/:sessionId`}>
              <Session />
            </Route>
          </Switch>
        </SessionContext.Provider>
      </AdminDashboardContainer>
    </Container>
  );
}
