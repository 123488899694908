import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';

import {
  MultipleChoiceQuestion,
  useParticipantState,
  useSocket,
  useTimer,
} from 'src/Context';
import { GlobalSocketEmitEvents } from 'src/Context/SocketTypes';
import { disableAudio } from 'src/Helpers';

import {
  H2,
  MCQuestionContainer,
  OverlayContainer,
  VerticalButtonContainer,
  VerticalButtonGroup,
} from './Overlay.styled';
import { Overlay } from './PopUp';

import './Survey.css';
import { PleaseWaitView, Survey } from './SurveyOverlay';

type SurveyTypes = 'pleasant' | 'unpleasant';

interface ISurveyViewWithMC {
  mcQuestions: MultipleChoiceQuestion[];
  questionCount: number;
}

export function SurveyViewWithMC({
  mcQuestions,
  questionCount,
}: ISurveyViewWithMC): JSX.Element {
  const [selection, setSelection] = React.useState<number>(0);
  const socket = useSocket();
  const participant = useParticipantState();
  const surveyCode = React.useRef(uuidv4());

  React.useEffect(() => {
    disableAudio(true);
    return () => disableAudio(false);
  }, []);

  const handleSelection = (type: SurveyTypes, choice: number) => {
    socket.emit(GlobalSocketEmitEvents.RESPONSE_DATA, {
      type: 'SURVEY',
      responseData: {
        participantId: participant?.participantId,
        response: choice,
        surveyType: type,
        surveyCode: surveyCode.current,
        surveyIndex: `broken`,
        atClientTime: Date.now(),
      },
    });
    setSelection((p) => p + 1);
  };

  const surveyOrder = React.useMemo(() => {
    if (Math.random() > 0.5) {
      return ['pleasant' as SurveyTypes, 'unpleasant' as SurveyTypes];
    } else {
      return ['unpleasant' as SurveyTypes, 'pleasant' as SurveyTypes];
    }
  }, []);

  return (
    <Overlay>
      {selection !== 2 ? (
        <Survey
          handleSelection={handleSelection}
          type={surveyOrder[selection]}
        />
      ) : (
        <MCQuestionHandler
          questions={mcQuestions}
          questionCount={questionCount}
        />
      )}
    </Overlay>
  );
}

interface IMCQuestionHandler {
  questions: MultipleChoiceQuestion[];
  questionCount: number;
}

function MCQuestionHandler({
  questions,
  questionCount,
}: IMCQuestionHandler): JSX.Element {
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [optionsLocked, setOptionsLocked] = React.useState(true);
  const timer = useTimer();
  const { participantId } = useParticipantState();
  const socket = useSocket();

  const intervalName = `MC ${questionCount}-${questionIndex + 1}`;

  const handleSelection = React.useCallback(
    async (ans: string) => {
      await timer.getInterval(intervalName);
      setOptionsLocked(true);
      setQuestionIndex((i) => i + 1);
      socket.emit(GlobalSocketEmitEvents.RESPONSE_DATA, {
        type: 'MC',
        responseData: {
          mcQuestionIndex: intervalName,
          participantId,
          atClientTime: Date.now(),
          isCorrect: ans === questions[questionIndex].correctAnswer,
        },
      });
    },
    [intervalName, participantId, questionIndex, questions, socket, timer],
  );

  React.useEffect(() => {
    if (questionIndex < 3) {
      timer.beginInterval(intervalName);
    }
  }, [intervalName, questionIndex, timer]);

  React.useEffect(() => {
    if (optionsLocked) {
      const buttonLockTimer = setTimeout(() => setOptionsLocked(false), 500);
      return () => clearTimeout(buttonLockTimer);
    }
  }, [optionsLocked]);

  if (questionIndex === 3) {
    return <PleaseWaitView emitTo={'TEXT_QUESTION_MACHINE'} />;
  }

  return (
    <Overlay>
      <OverlayContainer>
        <MCQuestionContainer>
          <H2>{questions[questionIndex].prompt}</H2>
          <VerticalButtonGroup>
            {!optionsLocked &&
              generateResponses(questions[questionIndex].choices)}
          </VerticalButtonGroup>
        </MCQuestionContainer>
      </OverlayContainer>
    </Overlay>
  );

  function generateResponses(options: string[]): JSX.Element[] {
    const letters = ['A', 'B', 'C'];
    return options.map((o, i) => {
      return (
        <VerticalButtonContainer key={i}>
          <Button
            className="btn"
            variant="outline-info"
            disabled={optionsLocked}
            onClick={() => handleSelection(o)}
            name="mc-question"
            id={`option-${o}`}
          >
            {' '}
            {letters[i]}) {o}
          </Button>
        </VerticalButtonContainer>
      );
    });
  }
}
