import styled from 'styled-components';

export const TextContainer = styled.div`
  text-align: left;
  margin: 20px 10px 10px;
`;

export const MainContainer = styled.div`
  height: 100%;
  border: 2px solid red;
  position: relative;
`;
