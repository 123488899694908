import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  TextContainer,
} from 'src/GlobalComponents';

import { Prompt } from '../../../Prompt';

export function MakeSelection({
  showBeginCollection,
  resetButtons,
}: {
  showBeginCollection: boolean;
  resetButtons: boolean;
}): JSX.Element {
  const [disableButton, setDisableButton] = React.useState<boolean>(false);
  const [disableDemoButton, setDisableDemoButton] = React.useState<boolean>(
    false,
  );

  const socket = useSocket();
  const { isTeacher } = useParticipantState();

  React.useEffect(() => {
    if (resetButtons) {
      setDisableDemoButton(false);
      setDisableButton(false);
    }
  }, [resetButtons]);

  const handleDemoClick = () => {
    setDisableDemoButton(true);
    if (!showBeginCollection) {
      socket.emitGuardedProceed('DEMO_MACHINE');
    } else {
    }
  };

  const handleBeginClick = () => {
    setDisableButton(true);
    socket.emitGuardedProceed('MAIN', 'Demo');
  };

  return (
    <Container>
      <>
        <ContentContainer>
          {showBeginCollection && (
            <Prompt message="click when ready to begin" alignment="center" />
          )}

          {isTeacher && !showBeginCollection && (
            <>
              <Prompt
                message={isTeacher ? '[Read Aloud]' : '[Listen]'}
                alignment="center"
              />
              <TextContainer fontSize={1.1}>
                I'm going to ask you a question, then click a button that
                indicates that I am done reading the question.{' '}
                <u>
                  This button also starts a timer, and generates a button for
                  you to click when you are ready to respond out loud.
                </u>{' '}
                <em>
                  Please don't respond out loud until you have clicked the
                  "Ready to Respond" button.
                </em>{' '}
                <br />
                After you click the button, and then respond out loud, we will
                both take a survey where we don't see each other.
                <br />
                Once we see each other again, I will give you feedback, and we
                will move on to the next question.
              </TextContainer>
            </>
          )}
        </ContentContainer>
      </>
      <FooterContainer>
        {!showBeginCollection && (
          <Button disabled={disableDemoButton} onClick={handleDemoClick}>
            Begin Practice
          </Button>
        )}
        {showBeginCollection && (
          <Button disabled={disableButton} onClick={handleBeginClick}>
            Begin Collection
          </Button>
        )}
      </FooterContainer>
    </Container>
  );
}
