import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { useParticipantState, useSocket } from 'src/Context';

import {
  Container,
  ContentContainer,
  TextContainer,
  FooterContainer,
} from 'src/GlobalComponents';

import { Prompt } from '../../../Prompt';

export function ExplainParity(): JSX.Element {
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
  const { isTeacher } = useParticipantState();
  const socket = useSocket();

  React.useEffect(() => {
    setTimeout(() => setButtonDisabled(false), 3000);
  }, []);

  const handleClick = () => {
    setButtonDisabled(true);
    socket.emitGuardedProceed('MAIN', 'Parity');
  };

  return (
    <Container headerType="none">
      <ContentContainer>
        <>
          <Prompt
            alignment="center"
            message={isTeacher ? '[Read aloud]' : '[Listen]'}
          />
          {isTeacher && (
            <>
              <TextContainer marginTop={40}>
                For each of these problems, the last gear turns in the same
                direction as the first if the total number of gears in the
                sequence is odd, and opposite the direction of the first if the
                total number of gears are even.
              </TextContainer>
              <TextContainer marginTop={20}>
                In a closed loop however, if the number of gears is odd, no
                matter the direction, the gears will lock and not turn. If the
                number of gears is even, the first rule still applies, for
                example, the last gear will turn in the opposite direction of
                the first.
              </TextContainer>
              <TextContainer marginTop={20}>
                Do you want me to repeat this explanation?
              </TextContainer>
            </>
          )}
        </>
      </ContentContainer>
      <FooterContainer>
        <Button disabled={buttonDisabled} onClick={handleClick}>
          Continue
        </Button>
      </FooterContainer>
    </Container>
  );
}
