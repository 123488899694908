import styled from 'styled-components';

export const H2 = styled.h2`
  text-align: center;
  margin: 30px 10px;
`;

export const InstructionsContainer = styled.div`
  height: 100vh;
  width: 100vw;
  margin: auto;
  background-color: white;
  text-align: center;
  padding: 10px 100px;
`;
