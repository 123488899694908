import styled from 'styled-components';

interface ITextContainer {
  color?: string;
  alignment?: 'left' | 'center';
  marginTop?: number;
  marginBottom?: number;
  fontSize?: number;
  marginLeft?: number;
}

export const TextContainer = styled.div<ITextContainer>`
  color: ${({ color }) => `${color ? color : ''}`};
  text-align: ${({ alignment }) => alignment};
  margin: 20px 10px 10px;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  font-size: ${({ fontSize }) => `${fontSize}em`};
  white-space: pre-line;
  max-height: 100%;
`;

TextContainer.defaultProps = {
  alignment: 'left',
  marginTop: 0,
  marginBottom: 0,
  fontSize: 1.5,
};
