import { TextContainer } from 'src/GlobalComponents';
import { Overlay, OverlayContainer } from 'src/GlobalComponents/Overlay';
import { Timer } from './Timer';

export function GoodbyePage(): JSX.Element {
  return (
    <Overlay>
      <OverlayContainer>
        <TextContainer alignment="center" marginTop={50}>
          <Timer />
          Congratulations!!! You have completed participation in the
          Teacher-Student Interaction Portal.
        </TextContainer>

        <TextContainer alignment="center" marginTop={30}>
          <b>
            Please complete your participation in the current study by
            <a
              href="https://tccolumbia.qualtrics.com/jfe/form/SV_9T6VPG4reczlw6W"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              completing the post-survey here
            </a>
            .
          </b>
        </TextContainer>

        <TextContainer alignment="center" marginTop={30}>
          Thank you for joining this study, we hope you enjoyed the experience.
        </TextContainer>

        <TextContainer alignment="center" marginTop={30}>
          If you have any questions, concerns, or comments about the experience,
          please contact the Study Coordinator at{' '}
          <a href="mailto:jsf2167@tc.columbia.edu">jsf2167@tc.columbia.edu</a>.
        </TextContainer>

        <TextContainer alignment="center" marginTop={30}>
          Your participation will help us learn about how teachers and students
          can each improve their classroom relationships, and if you would like,
          you can request to be updated with results of the study as they become
          available by sending a request to the email above.
        </TextContainer>
      </OverlayContainer>
    </Overlay>
  );
}
