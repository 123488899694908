import { useMachine } from '@xstate/react';
import * as React from 'react';
import { assign, Machine } from 'xstate';

import { useSocket } from 'src/Context';
import { ScienceBriefing, ScienceOverview } from './Components';
import { ScienceQuestionViews } from './ScienceQuestionsViews';
import { devController } from 'src/Config';
import { CameraCheckpoint } from '../CameraCheckpoint';

enum SocketOnEvents {
  PROCEED_SCIENCE_MACHINE = 'PROCEED SCIENCE_MACHINE',
}

type ComponentNames =
  | 'cameraCheckpoint'
  | 'overview'
  | 'scienceBriefing'
  | 'scienceQuestionMachine';

const components: { [key in ComponentNames]: React.FC } = {
  cameraCheckpoint: () => <CameraCheckpoint target="SCIENCE_MACHINE" />,
  overview: ScienceOverview,
  scienceBriefing: ScienceBriefing,
  scienceQuestionMachine: ScienceQuestionViews,
};

export function ScienceMachine(): JSX.Element {
  const socket = useSocket();
  const [state, send] = useMachine(scienceMachine);

  React.useEffect(() => {
    socket.on(SocketOnEvents.PROCEED_SCIENCE_MACHINE, () =>
      send('PROCEED_SCIENCE_MACHINE'),
    );

    return () => {
      socket.off(SocketOnEvents.PROCEED_SCIENCE_MACHINE);
    };
  }, [send, socket]);

  const Component = components[state.value as ComponentNames];

  return <Component />;
}

const scienceMachine = Machine<any, { type: 'PROCEED_SCIENCE_MACHINE' }>({
  id: 'scienceMachine',
  initial: devController.sciencePreSections
    ? 'cameraCheckpoint'
    : 'scienceQuestionMachine',
  context: {
    component: devController.sciencePreSections
      ? 'cameraCheckpoint'
      : 'scienceQuestionMachine',
  },
  states: {
    cameraCheckpoint: {
      on: {
        PROCEED_SCIENCE_MACHINE: {
          target: 'overview',
          actions: [
            assign({
              component: 'overview',
            }),
          ],
        },
      },
    },
    overview: {
      on: {
        PROCEED_SCIENCE_MACHINE: {
          target: 'scienceBriefing',
          actions: [
            assign({
              component: 'scienceBriefing',
            }),
          ],
        },
      },
    },
    scienceBriefing: {
      on: {
        PROCEED_SCIENCE_MACHINE: {
          target: 'scienceQuestionMachine',
          actions: [
            assign({
              component: 'scienceQuestionMachine',
            }),
          ],
        },
      },
    },
    scienceQuestionMachine: {
      type: 'final',
    },
  },
});
