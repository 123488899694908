import moment from 'moment';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';

import { API } from 'src/Services/api';
import {
  useSocket,
  useUpdateAlertMessage,
  useUpdateParticipantState,
} from 'src/Context';

import './Home.css';
import { Container, Header, InformationContainer } from './Home.styled';

const PARTICIPANT_ENTRY = 'PARTICIPANT ENTRY';

export function Home(): JSX.Element {
  const setAlert = useUpdateAlertMessage();
  const setParticipant = useUpdateParticipantState();
  const [participantId, setParticipantId] = React.useState<string>('');
  const [disableButton, setDisableButton] = React.useState(false);
  const socket = useSocket();

  const history = useHistory();
  const inputRef = React.useRef<null | HTMLInputElement>(null);

  const handleErrors = (e: string) => {
    setAlert({ message: e });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParticipantId(e.target.value.toUpperCase());
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <>
      <Container>
        <Form>
          <Header>Welcome to the Teacher-Student Interaction Portal!</Header>

          <InformationContainer>
            <p>Thank you for joining this study.</p>
            <p>You are about to engage in two teaching and learning tasks.</p>
            <p>
              <u>Please take an opportunity now to set up your environment:</u>{' '}
              remove distractions, turn off / silence devices, get everything
              you may need for the next 45 minutes or so near you.
            </p>

            <p>
              Before we begin, there will be a few briefing slides with
              instructions to make sure that we are able to capture high quality
              data from your interaction.
            </p>
            <p>
              Please pay close attention to all instructions,{' '}
              <strong>READ EVERYTHING CAREFULLY</strong>, and if you have any
              questions before you begin, email{' '}
              <a href="mailto: jsf2167@tc.columbia.edu">
                jsf2167@tc.columbia.edu
              </a>{' '}
              before proceeding.
            </p>
            <p>
              Thank you again for participating in these efforts to learn about
              teaching and learning!
            </p>
          </InformationContainer>

          <Form.Group controlId="participantId">
            <Form.Label>Participant ID:</Form.Label>
            <Form.Control
              className="participant-id-input"
              type="text"
              ref={inputRef}
              placeholder="1S_ABCD"
              value={participantId}
              onChange={handleChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="input"
            disabled={disableButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );

  async function handleSubmit(e: React.MouseEvent<HTMLInputElement>) {
    e.preventDefault();
    setDisableButton(true);
    if (!participantId) {
      handleErrors('Please enter a Participant Id.');
      return;
    }
    try {
      const {
        found,
        participant,
        session_occured_on,
      } = await API.checkParticipant(participantId);
      if (found) {
        setParticipant(participant);
        setAlert({ message: '' });
        socket.onConnect = () => socket.emit(PARTICIPANT_ENTRY, participant);
        socket.setParticipantId(participantId);
        socket.initSocket();
        setTabName(participant.isTeacher);
        history.push('/survey/begin');
      } else {
        if (session_occured_on) {
          throw new Error(
            `Session occured on ${moment
              .utc(session_occured_on)
              .local()
              .format(
                'dddd, MMMM Do, YYYY h:mm:ss A',
              )}. Contact your session administrator if this seems incorrect.`,
          );
        } else {
          throw new Error(notFoundErrorMessage);
        }
      }
    } catch (e) {
      handleErrors((e as Error).message);
    } finally {
      setDisableButton(false);
    }
  }
}

function setTabName(isTeacher: boolean) {
  document.title = isTeacher ? '[Teacher] Portal' : '[Student] Portal';
}

const notFoundErrorMessage =
  'Unable to find a user with that Participant Id. Please contact the survey admininstrator.';
