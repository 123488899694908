import { TextContainer } from 'src/GlobalComponents';

import sr_2_table_1 from './assets/STIP_SR_Table1.png';
import sr_3_figure_1 from './assets/STIP_SR_Figure1.svg';
import sr_3_figure_2 from './assets/STIP_SR_Figure2.svg';

const ImageContainer = ({
  alt,
  src,
  flexGrow,
  maxHeight,
  maxWidth,
}: {
  src: string;
  alt: string;
  flexGrow?: number;
  maxHeight?: number | string;
  maxWidth?: number | string;
}) => (
  <div style={{ margin: 'auto' }}>
    <img src={src} alt={alt} style={{ maxHeight, maxWidth, flexGrow }} />
  </div>
);

const Exp2contentPassage = (
  <TextContainer fontSize={1}>
    The effects of two types of conversations on creativity were studied. One
    group of subjects (the “Monotony Group”) listened to a dull, monotonous
    12-minute recording of a boring conversation. Another group (the “Novelty
    Group”) listened to an interesting, novel recording of an interesting
    conversation of the same length.
    <br />
    <br />
    Both groups were shown a series of pictures of people and were asked to
    write a story suggested by each picture. The stories were scored for their
    degree of novelty and each student’s story was labeled “Low,” “Middle,” or
    “High” in novelty. The possible results table is shown on your screen.
  </TextContainer>
);

const experimentContent = {
  exp1: {
    passages: {
      teacherAndStudent1: (
        <>
          <TextContainer fontSize={1.2}>
            A student is witnessed studying for a test they have tomorrow by
            staying up all night testing themselves with flashcards. Their
            friend asks if they would not rather get a full night’s sleep, but
            they insist, no, this is going to do more for their mental
            performance.
            <br />
            <br />
            How could you test the student’s assertion? Describe your proposed
            experiment and provide justifications for each aspect of your
            experimental design. This should take about 3 minutes to answer out
            loud. You can click “Done Answering” at any time.
          </TextContainer>
        </>
      ),
    },
  },
  exp2: {
    passages: {
      teacher1: Exp2contentPassage,
      teacher2: (
        <>
          <TextContainer>
            What do you predict the researchers will find, and why is your
            prediction justified? What additional competing hypotheses ought to
            be considered? This should take about 3 minutes to answer out loud.
            You can click “Done Answering” at any time.
          </TextContainer>
          <br />
          <ImageContainer
            src={sr_2_table_1}
            alt="table-data"
            maxHeight={'13em'}
          />
        </>
      ),
      student1: (
        <>
          {Exp2contentPassage}
          <ImageContainer
            src={sr_2_table_1}
            alt="table-data"
            maxHeight={'13em'}
          />
        </>
      ),
    },
  },
  exp3: {
    passages: {
      teacher1: (
        <TextContainer>
          The figure on your screen shows a food chain. The number of snakes and
          mice in this food chain have been observed to decrease over time.
          These decreases are shown in the graph below the figure. Your task is
          to find reasons for these decreases. Say as many reasons as you can
          think of out loud.
        </TextContainer>
      ),
      teacher2: (
        <>
          <TextContainer>
            The figure on your screen shows a food chain. The number of snakes
            and mice in this food chain have been observed to decrease over
            time. These decreases are shown in the graph below the figure. Your
            task is to find reasons for these decreases. Say as many reasons as
            you can think of out loud.
          </TextContainer>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <ImageContainer
              src={sr_3_figure_1}
              maxHeight={'10em'}
              alt="food-chain"
            />
            <ImageContainer
              src={sr_3_figure_2}
              maxHeight={'9em'}
              alt="snake-rat-chart"
            />
          </div>
        </>
      ),

      student1: (
        <>
          <ImageContainer
            src={sr_3_figure_1}
            maxHeight={'13em'}
            alt="food-chain"
          />
          <ImageContainer
            src={sr_3_figure_2}
            maxHeight={'13em'}
            alt="snake-rat-chart"
          />
        </>
      ),
    },
  },
};

export const scienceQuestions = [
  {
    briefingId: '3',
    briefing: (
      <TextContainer>
        Please answer the question thoughtfully and logically, using scientific
        reasoning to develop your response. For all of your responses,{' '}
        <b>please answer out loud.</b>
      </TextContainer>
    ),
    content: {
      student: [experimentContent.exp1.passages.teacherAndStudent1],
      teacher: [experimentContent.exp1.passages.teacherAndStudent1],
    },
    question: {
      student: (
        <TextContainer>
          A student is witnessed studying for a test they have tomorrow by
          staying up all night testing themselves with flashcards. Their friend
          asks if they would not rather get a full night’s sleep, but they
          insist, no, this is going to do more for their mental performance.
        </TextContainer>
      ),
      teacher: (
        <TextContainer>
          Thank you. Now, scientific experiments attempt to control for
          pre-existing differences between initial conditions under comparison.
          <br />
          <br />
          Given this information, is there anything else you would like to add
          to your response before moving on?
        </TextContainer>
      ),
    },
  },
  {
    briefingId: '4',
    briefing: (
      <TextContainer>
        Thank you for your response. Now, for this question, I will briefly
        describe an experiment. Your task is to predict the outcomes of the
        described experiment, and to explain why you think those outcomes will
        be observed. <b>Please answer out loud.</b>
      </TextContainer>
    ),
    content: {
      student: [experimentContent.exp2.passages.student1],
      teacher: [
        experimentContent.exp2.passages.teacher1,
        experimentContent.exp2.passages.teacher2,
      ],
    },
    question: {
      student: experimentContent.exp2.passages.student1,
      teacher: (
        <TextContainer>
          Thank you. Also, previous research suggests that being in a positive
          mood increases people’s chances to generate novel ideas.
          <br />
          <br />
          Given this information, are there any other hypotheses you would like
          to add to your response, or any hypotheses you would like to adjust,
          before moving on?
        </TextContainer>
      ),
    },
  },
  {
    briefingId: '5',
    briefing: (
      <TextContainer>
        For this question, you will see a figure and a graph representing
        information over time. Your task is to think of explanations, or
        interpretations of the data, to account for the finding.{' '}
        <b>Please answer out loud.</b>
      </TextContainer>
    ),
    content: {
      student: [experimentContent.exp3.passages.student1],
      teacher: [
        experimentContent.exp3.passages.teacher1,
        experimentContent.exp3.passages.teacher2,
      ],
    },
    question: {
      student: experimentContent.exp3.passages.student1,
      teacher: (
        <TextContainer>
          Great. Now, every season, the hawks in the habitat migrate south for
          the winter.
          <br />
          <br />
          Given this information, are there any other reasons for the decreases
          in the snake and mice population that you would like to add to your
          response before moving on?
        </TextContainer>
      ),
    },
  },
];
