import styled from 'styled-components';

interface IMessageContainer {
  alignment?: 'left' | 'center';
  size?: 'small' | 'regular';
  marginTop?: number;
}
export const MessageContainer = styled.div<IMessageContainer>`
  text-align: ${({ alignment }) => alignment};
  font-size: ${({ size }) => (size === 'regular' ? '1.2em' : '1em')};
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 10px;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`;

MessageContainer.defaultProps = {
  alignment: 'left',
  size: 'regular',
  marginTop: 0,
};
