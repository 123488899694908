import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1500px;
`;

export const AdminDashboardContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  margin-top: 60px;
`;

export const Header = styled.div`
  height: 60px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
`;

export const HeaderButton = styled.button`
  height: 60px;
  padding: 0px 20px;
  display: flex;
  background-color: white;
  align-items: center;
  border: none;
  border-bottom: 1px solid black;
  &:hover {
    background-color: silver;
  }
`;

export const HeaderText = styled.div`
  font-size: 1.2em;
`;

export const Video = styled.video`
  width: 100%;
`;
export const MiniVideo = styled.video`
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid red;
  max-width: 300px;

  @media only screen and (max-width: 1000px) {
    position: static;
    height: unset;
    width: 100%;
  }
`;
