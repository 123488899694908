import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSocket } from 'src/Context';

interface IUseMediaStream {
  withVideo: boolean;
}

export function useMediaStream({ withVideo }: IUseMediaStream) {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [mediaStream, setStream] = React.useState<null | MediaStream>(null);
  const socket = useSocket();

  const initStream = React.useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: withVideo
          ? {
              width: 640,
              height: 480,
              frameRate: {
                min: 24,
                ideal: 30,
                max: 31,
              },
            }
          : false,
      });
      setStream(stream);
      setIsLoading(false);
    } catch (err) {
      alert(ERROR_MESSAGE);
      history.push('/');
    }
  }, [withVideo, history]);

  React.useEffect(() => {
    if (mediaStream === null && isLoading === true) {
      initStream();
    }
  }, [initStream, isLoading, mediaStream, socket]);

  React.useEffect(() => {
    if (mediaStream) {
      window.onunload = function () {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      };
    }
  }, [mediaStream]);

  return { isLoading, mediaStream };
}

const ERROR_MESSAGE =
  'This site requires the use of your microphone and in some cases camera to capture data. Please allow access and refresh the page. Other software that may be using your camera can also cause this problem.';
