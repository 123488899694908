import React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket, useTimer } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  TextContainer,
} from 'src/GlobalComponents';

import { Prompt } from '../../../Prompt';

interface IExplainReasoning {
  count?: number;
}
export function ExplainReasoning({
  count = 1,
}: IExplainReasoning): JSX.Element {
  const timer = useTimer();
  const { isTeacher } = useParticipantState();

  const socket = useSocket();

  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    timer.beginInterval(`Explain Reasoning ${count}`, isTeacher);
    setTimeout(() => setButtonDisabled(false), 3000);
  }, [count, isTeacher, timer]);

  const handleClick = async () => {
    if (buttonDisabled) {
      return;
    }

    setButtonDisabled(true);
    await timer.getInterval(`Explain Reasoning ${count}`);
    socket.emitProceed('GEAR_MACHINE');
  };

  return (
    <Container>
      <ContentContainer>
        <>
          <Prompt
            alignment="center"
            message={isTeacher ? '[Read aloud]' : '[Listen & Respond]'}
          />
          {isTeacher && (
            <>
              <TextContainer marginTop={40} alignment="center">
                Can you explain to me how you solved the problems?
              </TextContainer>
              <Prompt
                marginTop={40}
                alignment="center"
                message={'[Wait for Response]'}
              />
              <TextContainer marginTop={40} alignment="center">
                Thank you for your explanation.
              </TextContainer>
            </>
          )}
        </>
      </ContentContainer>
      <FooterContainer>
        {isTeacher && (
          <Button disabled={buttonDisabled} onClick={handleClick}>
            Continue with questions
          </Button>
        )}
      </FooterContainer>
    </Container>
  );
}
