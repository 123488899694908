import { useMachine } from '@xstate/react';
import * as React from 'react';
import { assign, Machine } from 'xstate';

import { useSocket } from 'src/Context';

import './DemoMachine.css';
import { MakeSelection } from './Components';
import { DemoQuestionViews } from './DemoQuestionViews';

enum SocketOnEvents {
  PROCEED_DEMO_MACHINE = 'PROCEED DEMO_MACHINE',
  RESET = 'RESET',
}

const components: { [key in ComponentNames]: React.FC } = {
  demoQuestionMachine: DemoQuestionViews,
  makeSelection: DemoQuestionViews,
};

export function DemoMachine(): JSX.Element {
  const socket = useSocket();
  const [state, send] = useMachine(gearMachine);

  const [showBeginCollection, setShowBeginCollection] = React.useState<boolean>(
    false,
  );
  const [resetButtons, setResetButtons] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (resetButtons) {
      const timer = setTimeout(() => setResetButtons(false), 500);
      return () => clearTimeout(timer);
    }
  }, [resetButtons]);

  React.useEffect(() => {
    socket.on(SocketOnEvents.PROCEED_DEMO_MACHINE, () => {
      if (!showBeginCollection) {
        setShowBeginCollection(true);
      }
      send('PROCEED_DEMO_MACHINE');
    });
    socket.on(SocketOnEvents.RESET, () => setResetButtons(true));

    return () => {
      socket.off(SocketOnEvents.PROCEED_DEMO_MACHINE);
      socket.off(SocketOnEvents.RESET);
    };
  }, [send, socket, showBeginCollection]);

  const Component = React.useMemo(() => {
    if (state.value === 'makeSelection') {
      return () => (
        <MakeSelection
          resetButtons={resetButtons}
          showBeginCollection={showBeginCollection}
        />
      );
    }
    return components[state.value as ComponentNames];
  }, [state.value, showBeginCollection, resetButtons]);

  return <Component />;
}

type ComponentNames = 'makeSelection' | 'demoQuestionMachine';

const gearMachine = Machine<any, { type: 'PROCEED_DEMO_MACHINE' }>({
  id: 'demoMachine',
  initial: 'makeSelection',
  context: {
    component: 'makeSelection',
  },
  states: {
    makeSelection: {
      on: {
        PROCEED_DEMO_MACHINE: {
          target: 'demoQuestionMachine',
          actions: [
            assign({
              component: 'demoQuestionMachine',
            }),
          ],
        },
      },
    },
    demoQuestionMachine: {
      on: {
        PROCEED_DEMO_MACHINE: {
          target: 'makeSelection',
          actions: [
            assign({
              component: 'makeSelection',
            }),
          ],
        },
      },
    },
  },
});
