import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { devController } from 'src/Config';
import {
  ExperimentSections,
  Machine,
  useParticipantState,
  useSocket,
} from 'src/Context';
import { GlobalSocketEmitEvents } from 'src/Context/SocketTypes';
import { disableAudio } from 'src/Helpers';

import {
  ButtonContainer,
  ButtonGroup,
  ImagesContainer,
  ImgContainer,
  OverlayContainer,
  ScaleContainer,
  TextContainer,
  TextImageContainer,
  WaitContainer,
} from './Overlay.styled';
import { Overlay } from './PopUp';
import Pleasant from './images/pleasant.png';
import Unpleasant from './images/unpleasant.png';
import PleasantText from './images/pleasant_text.png';
import UnpleasantText from './images/unpleasant_text.png';
import './Survey.css';

type SurveyTypes = 'pleasant' | 'unpleasant';

interface ISurvey {
  type: SurveyTypes;
  range?: [number, number];
  handleSelection: (type: SurveyTypes, value: number) => void;
}

export function SurveyView({
  emitTo: machine,
  isDemo,
  section,
  problem,
}: {
  emitTo: Machine;
  isDemo?: boolean;
  section?: ExperimentSections;
  problem?: number;
}): JSX.Element {
  const [selection, setSelection] = React.useState<number>(0);
  const socket = useSocket();
  const participant = useParticipantState();

  React.useEffect(() => {
    disableAudio(true);
    return () => disableAudio(false);
  }, []);

  React.useEffect(() => {
    if (devController.enableSurvey === false) {
      socket.emitGuardedProceed(machine, 'survey');
    }
  }, [machine, socket]);

  const handleSelection = (type: SurveyTypes, response: number) => {
    if (!isDemo) {
      socket.emit(GlobalSocketEmitEvents.RESPONSE_DATA, {
        type: 'SURVEY',
        responseData: {
          participantId: participant?.participantId,
          response,
          type,
          section,
          problem,
          atClientTime: Date.now(),
        },
      });
    }

    setSelection((p) => p + 1);
  };

  const surveyOrder = React.useMemo(() => {
    if (Math.random() > 0.5) {
      return ['pleasant' as SurveyTypes, 'unpleasant' as SurveyTypes];
    } else {
      return ['unpleasant' as SurveyTypes, 'pleasant' as SurveyTypes];
    }
  }, []);

  return (
    <Overlay>
      {selection !== 2 ? (
        <Survey
          handleSelection={handleSelection}
          type={surveyOrder[selection]}
        />
      ) : (
        <PleaseWaitView emitTo={machine} />
      )}
    </Overlay>
  );
}

export function PleaseWaitView({ emitTo: machine }: { emitTo: Machine }) {
  const socket = useSocket();

  React.useEffect(() => {
    socket.emitGuardedProceed(machine);
  }, [machine, socket]);
  return (
    <WaitContainer>
      <TextContainer>
        Please wait for the other participant to finish.
      </TextContainer>
    </WaitContainer>
  );
}

interface IButtonGenerator {
  isDisabled: boolean;
}

export function Survey({
  type,
  range = [0, 7],
  handleSelection,
}: ISurvey): JSX.Element {
  const [buttonsLocked, setButtonsLocked] = React.useState<boolean>(false);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (buttonsLocked) {
      timeout = setTimeout(() => setButtonsLocked(false), 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [buttonsLocked, setButtonsLocked]);
  const handleClick = (selection: number) => {
    handleSelection(type, selection);
    lockButtons();
  };

  const lockButtons = () => {
    setButtonsLocked(true);
  };

  const isPleasant = type === 'pleasant';

  return (
    <OverlayContainer>
      <ScaleContainer>
        <ImagesContainer>
          {isPleasant ? (
            <ImgContainer src={Pleasant} alt="how pleasant" />
          ) : (
            <ImgContainer src={Unpleasant} alt="how unpleasant" />
          )}
          {isPleasant ? (
            <TextImageContainer src={PleasantText} alt="how pleasant" />
          ) : (
            <TextImageContainer src={UnpleasantText} alt="how unpleasant" />
          )}
        </ImagesContainer>
        <ButtonGroup>
          <ButtonGenerator isDisabled={buttonsLocked} />
        </ButtonGroup>
      </ScaleContainer>
    </OverlayContainer>
  );

  function ButtonGenerator({ isDisabled }: IButtonGenerator): JSX.Element {
    let buttonArray = [];
    for (let i = range[0]; i <= range[1]; i++) {
      buttonArray.push(
        <ButtonContainer key={i}>
          <Button
            className="survey-button"
            size="lg"
            onClick={() => handleClick(i)}
            variant="outline-info"
            disabled={isDisabled}
          >
            {i}
          </Button>
        </ButtonContainer>,
      );
    }
    return <>{buttonArray}</>;
  }
}
