import { useCallback, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { GearType, GetInterval, useSocket } from 'src/Context';

import { AnswerButtonGroup } from '../AnswerButtonGroup';

interface IButtonController {
  gearType: GearType;
  getInterval: GetInterval;
  isTeacher: boolean;
  state: string;
  isLastQuestion: boolean;
}

export function ButtonController({
  gearType,
  getInterval,
  isTeacher,
  state,
  isLastQuestion,
}: IButtonController): JSX.Element | null {
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(true);

  const buttonTimerRef = useRef<number | null>(null);

  const disableButtons = useCallback(() => {
    setButtonsDisabled(true);
  }, []);

  useEffect(() => {
    buttonTimerRef.current = window.setTimeout(
      () => setButtonsDisabled(false),
      1000,
    );

    return () => {
      if (buttonTimerRef.current) {
        clearTimeout(buttonTimerRef.current);
      }
    };
  }, [state]);

  const buttonText = () => {
    switch (state) {
      case 'readProblem':
        return 'Done Reading';
      case 'readyToAnswer':
        return 'Click then Respond';
      case 'feedback':
        return 'Next Question';
      default:
        return null;
    }
  };

  const teacherViews = ['readProblem', 'feedback'];
  const studentCanViewButton = !isTeacher && state === 'readyToAnswer';
  const teacherCanViewButton = isTeacher && teacherViews.includes(state);

  const socket = useSocket();

  const handleClick = () => {
    if (buttonsDisabled) {
      return;
    }
    disableButtons();
    if (state === 'readProblem') {
      getInterval('Problem');
    } else if (state === 'feedback') {
      getInterval('Feedback');
    }

    socket.emitProceed(
      isLastQuestion && state === 'feedback'
        ? 'GEAR_MACHINE'
        : 'GEAR_QUESTION_MACHINE',
    );
  };

  const handleStudentClick = async () => {
    if (buttonsDisabled) {
      return;
    }
    disableButtons();
    getInterval('RT');
    socket.emitProceed('GEAR_QUESTION_MACHINE');
  };

  if (teacherCanViewButton) {
    return (
      <Button disabled={buttonsDisabled} onClick={handleClick}>
        {isLastQuestion && state === 'feedback' ? 'Continue' : buttonText()}
      </Button>
    );
  }

  if (studentCanViewButton) {
    return (
      <Button disabled={buttonsDisabled} onClick={handleStudentClick}>
        {buttonText()}
      </Button>
    );
  }

  if (isTeacher && state === 'answerProblem') {
    return (
      <AnswerButtonGroup
        getInterval={getInterval}
        showLockedButton={gearType === 'closed'}
      />
    );
  }

  return null;
}
