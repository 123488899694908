import styled from 'styled-components';

interface ITR {
  isClickable: boolean;
}

export const TR = styled.tr<ITR>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

export const CreateContainer = styled.div`
  margin-bottom: 20px;
  padding: 12px;
  display: 'flex';
  width: 300px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Icon = styled.div`
  width: 20px;
  &:hover {
    cursor: pointer;
  }
`;
