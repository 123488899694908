import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { PortalContainer } from './Overlay.styled';

interface IPopUp {
  children: React.ReactNode;
}

export function Overlay(props: IPopUp): React.ReactPortal {
  const targetDiv = document.getElementById('modal')!;

  return ReactDOM.createPortal(
    <PortalContainer>{props.children}</PortalContainer>,
    targetDiv,
  );
}
