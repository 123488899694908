import { useCallback, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';

import {
  BeginInterval,
  GetInterval,
  useParticipantState,
  useSocket,
  useTimer,
} from 'src/Context';
import { TextBrowser } from 'src/GlobalComponents';

import { scienceQuestions } from './experimentsData';
import { Prompt } from '../Prompt';
import { scienceQuestionMachine } from './ScienceQuestionMachine';
import { SurveyView } from 'src/GlobalComponents/Overlay/SurveyOverlay';
import { getPromptText } from './helpers';

enum SocketEvents {
  PROCEED_SCIENCE_QUESTION_MACHINE = 'PROCEED SCIENCE_QUESTION_MACHINE',
  TOGGLE_BUTTON_CONTROLLER = 'TOGGLE SCIENCE BUTTON CONTROLLER',
}

const DONE_READING = 'Done Reading';
const DONE_ANSWERING = 'Done Answering';

export function ScienceQuestionViews(): JSX.Element {
  const timer = useTimer();
  const socket = useSocket();
  const { isTeacher } = useParticipantState();
  const [state, send] = useMachine(scienceQuestionMachine);
  const [teacherControlsButton, setTeacherControlsButton] = useState(true);

  const questionIdx = state.context.passageIdx;

  useEffect(() => {
    socket.on(SocketEvents.TOGGLE_BUTTON_CONTROLLER, () =>
      setTeacherControlsButton((s) => !s),
    );
    socket.on(SocketEvents.PROCEED_SCIENCE_QUESTION_MACHINE, () => {
      send('PROCEED_SCIENCE_QUESTION_MACHINE');
    });

    return () => {
      socket.off(SocketEvents.TOGGLE_BUTTON_CONTROLLER);
      socket.off(SocketEvents.PROCEED_SCIENCE_QUESTION_MACHINE);
    };
  }, [send, socket]);

  const emitToggleButtonController = useCallback(
    () => socket.emit(SocketEvents.TOGGLE_BUTTON_CONTROLLER),
    [socket],
  );
  const proceedScienceQuestionMachine = useCallback(
    () => socket.emitProceed('SCIENCE_QUESTION_MACHINE'),
    [socket],
  );

  const beginInterval = useCallback(
    (intervalName: string, keepInterval: boolean) => {
      timer.beginInterval(intervalName, keepInterval);
    },
    [timer],
  );

  const getInterval = useCallback(
    (interval: string) => timer.getInterval(interval),
    [timer],
  );

  if (questionIdx > 2) {
    if (isTeacher) {
      socket.emitProceed('MAIN', 'science');
    }
    return <div>Please wait...</div>;
  }

  return (
    <>
      {
        {
          briefing: (
            <BriefingView
              isTeacher={isTeacher}
              questionIdx={questionIdx}
              beginInterval={beginInterval}
              proceedScienceQuestionMachine={proceedScienceQuestionMachine}
            />
          ),
          content: (
            <ContentView
              isTeacher={isTeacher}
              questionIdx={questionIdx}
              teacherControlsButton={teacherControlsButton}
              getInterval={getInterval}
              beginInterval={beginInterval}
              emitToggleButtonController={emitToggleButtonController}
              proceedScienceQuestionMachine={proceedScienceQuestionMachine}
            />
          ),
          question: (
            <QuestionView
              isTeacher={isTeacher}
              questionIdx={questionIdx}
              teacherControlsButton={teacherControlsButton}
              getInterval={getInterval}
              beginInterval={beginInterval}
              emitToggleButtonController={emitToggleButtonController}
              proceedScienceQuestionMachine={proceedScienceQuestionMachine}
            />
          ),
          survey: (
            <SurveyView
              emitTo={'SCIENCE_QUESTION_MACHINE'}
              problem={questionIdx}
              section={'science'}
            />
          ),
        }[state.value as string]
      }
    </>
  );
}

interface IBriefingView {
  beginInterval: BeginInterval;
  isTeacher: boolean;
  proceedScienceQuestionMachine: () => void;
  questionIdx: number;
}

interface IContentView {
  beginInterval: BeginInterval;
  emitToggleButtonController: () => void;
  isTeacher: boolean;
  getInterval: GetInterval;
  proceedScienceQuestionMachine: () => void;
  questionIdx: number;
  teacherControlsButton: boolean;
}

interface IQuestionView {
  beginInterval: BeginInterval;
  emitToggleButtonController: () => void;
  isTeacher: boolean;
  getInterval: GetInterval;
  proceedScienceQuestionMachine: () => void;
  questionIdx: number;
  teacherControlsButton: boolean;
}

const BriefingView = ({
  beginInterval,
  isTeacher,
  proceedScienceQuestionMachine,
  questionIdx,
}: IBriefingView) => {
  useEffect(() => {
    beginInterval(`SQ-${questionIdx + 1}a`, isTeacher);
  }, [questionIdx, beginInterval, isTeacher]);

  return (
    <>
      <Prompt alignment="center" message={getPromptText(isTeacher, true)} />
      {isTeacher && (
        <TextBrowser
          key="briefing"
          onFinalClick={proceedScienceQuestionMachine}
          finalMessage={DONE_READING}
          content={[scienceQuestions[questionIdx].briefing]}
        />
      )}
    </>
  );
};

const ContentView = ({
  beginInterval,
  emitToggleButtonController,
  getInterval,
  isTeacher,
  teacherControlsButton,
  proceedScienceQuestionMachine,
  questionIdx,
}: IContentView) => {
  useEffect(() => {
    if (!teacherControlsButton) {
      beginInterval(`SR-${questionIdx + 1}a`, !isTeacher);
    }
  }, [beginInterval, isTeacher, questionIdx, teacherControlsButton]);

  const toggleButtonsAndGetInterval = useCallback(() => {
    getInterval(`SQ-${questionIdx + 1}a`);
    emitToggleButtonController();
  }, [emitToggleButtonController, getInterval, questionIdx]);

  const proceedScienceMachineAndEmitToggleButton = useCallback(() => {
    getInterval(`SR-${questionIdx + 1}a`);
    emitToggleButtonController();
    proceedScienceQuestionMachine();
  }, [
    emitToggleButtonController,
    getInterval,
    proceedScienceQuestionMachine,
    questionIdx,
  ]);

  const teacherContent = () =>
    questionIdx === 2 ? (
      <TextBrowser
        showPrompt
        key="content-teacher-weird"
        content={scienceQuestions[questionIdx].content.teacher}
        override={[
          {
            onClick: toggleButtonsAndGetInterval,
            showButton: true,
          },
          {
            onClick: () => null,
            showButton: false,
          },
        ]}
      />
    ) : (
      <TextBrowser
        showPrompt
        key="content-teacher"
        content={scienceQuestions[questionIdx].content.teacher}
        finalMessage={DONE_READING}
        onFinalClick={toggleButtonsAndGetInterval}
        hideFinalButton={!teacherControlsButton}
      />
    );

  return (
    <>
      <Prompt
        alignment="center"
        message={getPromptText(isTeacher, teacherControlsButton)}
      />

      {isTeacher ? (
        teacherContent()
      ) : (
        <TextBrowser
          key="content-student"
          content={scienceQuestions[questionIdx].content.student}
          finalMessage={DONE_ANSWERING}
          onFinalClick={proceedScienceMachineAndEmitToggleButton}
          hideFinalButton={teacherControlsButton}
        />
      )}
    </>
  );
};

const QuestionView = ({
  beginInterval,
  emitToggleButtonController,
  getInterval,
  isTeacher,
  teacherControlsButton,
  proceedScienceQuestionMachine,
  questionIdx,
}: IQuestionView) => {
  useEffect(() => {
    beginInterval(`SQ-${questionIdx + 1}b`, isTeacher);
  }, [beginInterval, isTeacher, questionIdx]);

  useEffect(() => {
    if (!teacherControlsButton) {
      beginInterval(`SR-${questionIdx + 1}b`, !isTeacher);
    }
  }, [beginInterval, isTeacher, questionIdx, teacherControlsButton]);

  const getIntervalAndToggleController = useCallback(() => {
    getInterval(`SQ-${questionIdx + 1}b`);
    emitToggleButtonController();
  }, [emitToggleButtonController, getInterval, questionIdx]);

  const proceedMachine = useCallback(() => {
    getInterval(`SR-${questionIdx + 1}b`);
    emitToggleButtonController();
    proceedScienceQuestionMachine();
  }, [
    emitToggleButtonController,
    getInterval,
    proceedScienceQuestionMachine,
    questionIdx,
  ]);

  return (
    <>
      <Prompt
        alignment="center"
        message={getPromptText(isTeacher, teacherControlsButton)}
      />
      {isTeacher ? (
        <TextBrowser
          showPrompt
          key="question-teacher"
          finalMessage={DONE_READING}
          hideFinalButton={!teacherControlsButton}
          onFinalClick={getIntervalAndToggleController}
          content={[scienceQuestions[questionIdx].question.teacher]}
        />
      ) : (
        <TextBrowser
          key="question-student"
          finalMessage={DONE_ANSWERING}
          hideFinalButton={teacherControlsButton}
          onFinalClick={proceedMachine}
          content={[scienceQuestions[questionIdx].question.student]}
        />
      )}
    </>
  );
};
