import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';

import { MCResponse } from 'src/Context';
import { API } from 'src/Services/api';

export function MCResponsesTable({
  studentId,
}: {
  studentId: string;
}): JSX.Element {
  const params = useParams<{ sessionId: string }>();
  const [mcResponses, setMCResponses] = React.useState<MCResponse[]>([]);

  const fetchMCResponses = React.useCallback(async () => {
    const data: MCResponse[] = await API.session.getMCResponses(
      params.sessionId,
    );
    // const sortedData = data.sort((a,b) => {
    //   moment(a.createdAt
    // })
    setMCResponses(data);
  }, [params.sessionId]);

  React.useEffect(() => {
    fetchMCResponses();
  }, [fetchMCResponses]);

  return (
    <BTable striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Participant #</th>
          <th>Participant Type</th>
          <th>Problem</th>
          <th>Accuracy</th>
        </tr>
      </thead>
      <tbody>
        {mcResponses.map((m, idx) => (
          <tr key={idx}>
            <td>{idx}</td>
            <td>{m.participantId}</td>
            <td>{m.participantId === studentId ? 'Student' : 'Teacher'}</td>
            <td>figure out</td>
            <td>{m.isCorrect}</td>
          </tr>
        ))}
      </tbody>
    </BTable>
  );
}
