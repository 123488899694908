import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';

import { Intervals } from 'src/Context';
import { API } from 'src/Services/api';

export function ReactionTimeTable({
  studentId,
}: {
  studentId: string;
}): JSX.Element {
  const params = useParams<{ sessionId: string }>();
  const [intervals, setIntervals] = React.useState<Intervals[]>([]);

  const fetchTimestamps = React.useCallback(async () => {
    const data = await API.session.getTimestamps(params.sessionId);
    setIntervals(data);
  }, [params.sessionId]);

  React.useEffect(() => {
    fetchTimestamps();
  }, [fetchTimestamps]);

  return (
    <BTable striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Participant #</th>
          <th>Participant Type</th>
          <th>Problem</th>
          <th>Reaction Time (ms)</th>
        </tr>
      </thead>
      <tbody>
        {intervals.map((i, idx) => (
          <tr key={idx}>
            <td>{idx}</td>
            <td>{i.participantId}</td>
            <td>{i.participantId === studentId ? 'Student' : 'Teacher'}</td>
            <td>{i.eventName}</td>
            <td>{i.interval}</td>
          </tr>
        ))}
      </tbody>
    </BTable>
  );
}
