import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { useSocket, useTimer } from 'src/Context';

import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';

import './GreetingComponent.css';
import { H2 } from './GreetingComponent.styled';

export function GreetingComponent(): JSX.Element {
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const timer = useTimer();
  const socket = useSocket();

  React.useEffect(() => {
    timer.beginInterval('Greeting');
  }, [timer]);

  const handleClick = async () => {
    setIsClicked(true);
    await timer.getInterval('Greeting');
    socket.emitGuardedProceed('MAIN', 'Greeting');
  };

  return (
    <Container>
      <HeaderContainer>
        <H2>Say Hello!</H2>
      </HeaderContainer>
      <ContentContainer>
        <TextContainer alignment="center">
          <h3>
            When both of you are ready to begin the teaching and learning task,
            click the button below.
          </h3>
        </TextContainer>
      </ContentContainer>
      <FooterContainer>
        <Button
          variant="outline-primary"
          onClick={handleClick}
          disabled={isClicked}
        >
          {isClicked ? 'Please Wait...' : 'Ready to Begin!'}
        </Button>
      </FooterContainer>
    </Container>
  );
}
