import { toOrdinal } from 'number-to-words';
import { useEffect } from 'react';

import { BeginInterval } from 'src/Context';
import { TextContainer } from 'src/GlobalComponents';
import { Prompt } from '../../../Prompt';

import { ResponseContainer } from './GearFeedback.styled';
interface IGearFeedback {
  answer: string;
  beginInterval: BeginInterval;
  correctAnswer: string;
  gearCount: number;
  isTeacher: boolean;
}

export function GearFeedback({
  answer,
  beginInterval,
  correctAnswer,
  gearCount,
  isTeacher,
}: IGearFeedback): JSX.Element {
  useEffect(() => {
    beginInterval(`Feedback`, isTeacher);
  }, [beginInterval, isTeacher]);

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  if (!answer || !correctAnswer) {
    return <></>;
  }

  if (!isTeacher) {
    return <Prompt alignment="center" marginTop={20} message={'[Listen]'} />;
  }

  const response = () => {
    if (answer === 'locked') {
      return answer === correctAnswer
        ? `"Yes, the ${toOrdinal(gearCount)} gear is locked."`
        : `"No, the ${toOrdinal(gearCount)} gear is not locked."`;
    }
    return answer === correctAnswer
      ? `"Yes, the ${toOrdinal(gearCount)} gear is turning ${answer}."`
      : `"No, the ${toOrdinal(gearCount)} gear is not turning ${answer}."`;
  };

  return (
    <>
      <TextContainer alignment="center" marginTop={10} fontSize={1.2}>
        Student's Response:
      </TextContainer>
      <TextContainer alignment="center" fontSize={1.2}>
        {' '}
        {capitalize(answer)}.
      </TextContainer>
      <ResponseContainer>
        <Prompt alignment="center" marginTop={20} message={'[READ ALOUD]'} />
        {response()}
      </ResponseContainer>
    </>
  );
}
