import { TextContainer } from 'src/GlobalComponents';
import { Prompt } from '../../../Prompt';

import { ResponseContainer } from './DemoFeedback.styled';

interface IGearFeedback {
  answer: string;
  correctAnswer: string;
  isTeacher: boolean;
}

export function DemoFeedback({
  answer,
  correctAnswer,
  isTeacher,
}: IGearFeedback): JSX.Element {
  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  if (!answer || !correctAnswer) {
    return <></>;
  }

  if (!isTeacher) {
    return <Prompt alignment="center" marginTop={20} message={'[Listen]'} />;
  }

  return (
    <>
      <TextContainer alignment="center" marginTop={10} fontSize={1.2}>
        Student's Response:
      </TextContainer>
      <TextContainer alignment="center" fontSize={1.2}>
        {' '}
        {capitalize(answer)}.
      </TextContainer>
      <ResponseContainer>
        <Prompt alignment="center" marginTop={20} message={'[READ ALOUD]'} />
        {answer === correctAnswer
          ? `"Yes, ${answer} is correct."`
          : `"No, ${answer} is incorrect."`}
      </ResponseContainer>
    </>
  );
}
