import moment from 'moment';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import BTable from 'react-bootstrap/Table';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useHistory, useRouteMatch } from 'react-router';

import {
  ExperimentSections,
  SessionContext,
  useUpdateAlertMessage,
} from 'src/Context';
import { API } from 'src/Services/api';

import {
  CreateContainer,
  Icon,
  IconContainer,
  TR,
} from './SessionsTable.styled';
import TrashIcon from './trash.svg';
import GearIcon from './gear.svg';
import Form from 'react-bootstrap/Form';

export interface ISession {
  sessionId: string;
  teacherId: string;
  studentId: string;
  startTime: string;
  endTime: string;
  videoEnabled: boolean;
  useMedia: boolean;
  sections: ExperimentSections[];
}

interface ExperimentConfig {
  experiments: {
    gears: boolean;
    text: boolean;
    science: boolean;
  };
  count: number;
}

export function SessionsTable(): JSX.Element {
  const [sessions, setSessions] = React.useState<ISession[]>([]);
  const setSessionData = React.useContext(SessionContext)[1];

  const setAlert = useUpdateAlertMessage();

  const history = useHistory();
  const match = useRouteMatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<ExperimentConfig['count']>(1);
  const [experimentSelection, setExperimentSelection] = useState<
    ExperimentConfig['experiments']
  >({
    gears: true,
    text: false,
    science: true,
  });

  const fetchSessions = React.useCallback(async () => {
    try {
      const sessions = await API.sessions.getSessionsByAdmin();
      setSessions(sessions);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  const handleCount = (newCount: number) => {
    if (newCount < 1 || newCount > 10) {
      return;
    }
    setCount(newCount);
  };

  const handleExperimentSelection = (
    config: Partial<ExperimentConfig['experiments']>,
  ) => {
    setExperimentSelection((c) => ({
      ...c,
      ...config,
    }));
  };

  const handleRowClick = (session: ISession) => {
    if (!session.startTime) {
      return;
    }
    setSessionData({
      teacherId: session.teacherId,
      studentId: session.studentId,
    });
    history.push(`${match.path}/${session.sessionId}`);
  };

  const deleteSession = async (e: React.MouseEvent, sessionId: string) => {
    e.stopPropagation();
    try {
      const updatedSessions = await API.sessions.deleteSession(sessionId);
      setSessions(updatedSessions);
    } catch (e) {
      console.log('Problem deleting session');
    }
  };

  const createSession = async () => {
    setLoading(true);
    try {
      const res = await API.sessions.createSession({
        count,
        experiments: experimentSelection,
      });
      fetchSessions();

      if (res.error) {
        console.log(res.error);
      }
    } catch (e) {
      setLoading(false);
      console.log('Problem creating sessions');
    }
  };

  const processSession = async (e: React.MouseEvent, sessionId: string) => {
    e.stopPropagation();

    try {
      const { message } = await API.sessions.processSession(sessionId);
      setAlert({ message, type: 'success' });
    } catch (e) {
      console.log(e);
      setAlert({ message: 'Unknown error occured' });
    }
  };

  return (
    <>
      <CreateContainer>
        <Form>
          <Form.Group>
            <Form.Label>Create Sessions</Form.Label>
            <span style={{ display: 'flex' }}>
              <div>
                <ToggleButton
                  size="sm"
                  type="checkbox"
                  variant="ouline-primary"
                  checked={experimentSelection.gears}
                  value="config-option-1"
                  onClick={() =>
                    handleExperimentSelection({
                      gears: !experimentSelection.gears,
                    })
                  }
                >
                  <span style={{ paddingLeft: 4 }}>Gears</span>
                </ToggleButton>
                <ToggleButton
                  size="sm"
                  type="checkbox"
                  variant="ouline-primary"
                  checked={experimentSelection.text}
                  value="config-option-2"
                  onClick={() =>
                    handleExperimentSelection({
                      text: !experimentSelection.text,
                    })
                  }
                >
                  <span style={{ paddingLeft: 4 }}>Text</span>
                </ToggleButton>
                <ToggleButton
                  size="sm"
                  type="checkbox"
                  variant="ouline-primary"
                  checked={experimentSelection.science}
                  value="config-option-2"
                  name="test"
                  onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    handleExperimentSelection({
                      science: !experimentSelection.science,
                    });
                  }}
                >
                  <span style={{ paddingLeft: 4 }}>Science</span>
                </ToggleButton>
                <Form.Control
                  size="sm"
                  type="number"
                  name="count"
                  value={count}
                  onChange={({ target: { value } }) => {
                    handleCount(Number(value));
                  }}
                  onSubmit={(e: any) => {
                    e.preventDefault();
                  }}
                  disabled={loading}
                />
              </div>
            </span>
          </Form.Group>
        </Form>

        <Button disabled={loading} variant="primary" onClick={createSession}>
          Create Session(s)
        </Button>
      </CreateContainer>
      <BTable striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Student</th>
            <th>Teacher</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Sections</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((s, idx) => (
            <TR
              key={idx}
              onClick={() => handleRowClick(s)}
              isClickable={!!s.startTime}
            >
              <td>{idx + 1}</td>
              <td>{s.studentId}</td>
              <td>{s.teacherId}</td>
              <td>
                {s.startTime
                  ? getDate(s.startTime)
                  : 'This session has not occured'}
              </td>
              <td> {s.endTime ? getDate(s.endTime) : 'N/A'}</td>
              <td>{s.sections.join(', ')}</td>
              <td>
                <IconContainer>
                  <Icon onClick={(e) => processSession(e, s.sessionId)}>
                    <img src={GearIcon} alt="process" />
                  </Icon>
                  <Icon onClick={(e) => deleteSession(e, s.sessionId)}>
                    <img src={TrashIcon} alt="Trash" />
                  </Icon>
                </IconContainer>
              </td>
            </TR>
          ))}
        </tbody>
      </BTable>
    </>
  );
}
const getDate = (date: string): string =>
  moment.utc(date).local().format('dddd, MMMM Do, YYYY h:mm:ss A');
