import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { SessionContext } from 'src/Context';

import {
  GearResponsesTable,
  MCResponsesTable,
  ReactionTimeTable,
  SurveyResponsesTable,
  VideoRecordings,
} from './Tables';

export function Session(): JSX.Element {
  const [key, setKey] = React.useState<string>('video');
  const session = React.useContext(SessionContext)[0];

  return (
    <Tabs id="all-tables" activeKey={key} onSelect={(k) => setKey(k as string)}>
      <Tab eventKey="video" title="Video/Audio">
        <VideoRecordings
          studentId={session!.studentId}
          teacherId={session!.teacherId}
        />
      </Tab>
      <Tab eventKey="reactions" title="Reaction Time">
        <ReactionTimeTable studentId={session!.studentId} />
      </Tab>
      <Tab eventKey="gears" title="Gears Responses">
        <GearResponsesTable studentId={session!.studentId} />
      </Tab>
      <Tab eventKey="survey" title="Surveys Responses">
        <SurveyResponsesTable studentId={session!.studentId} />
      </Tab>
      <Tab eventKey="mc" title="Multiple Choice">
        <MCResponsesTable studentId={session!.studentId} />
      </Tab>
    </Tabs>
  );
}
