export const isProd = true;
export const withDocker = false;

const allTrue = false;
export const devController = {
  enablePractice: isProd || allTrue || false,
  enableSurvey: isProd || allTrue || false,
  sciencePreSections: isProd || allTrue || false,
  gearsPreSections: isProd || allTrue || true,
};
