import { Participant } from './ParticipantContext';

export enum GlobalSocketOnEvents {
  BEGIN_RECORDING = 'BEGIN RECORDING',
  PARTICIPANTS_READY = 'PARTICIPANTS READY',
  BOTH_PROCEED = 'BOTH PROCEED',
}

export enum GlobalSocketEmitEvents {
  PARTICIPANT_ENTRY = 'PARTICIPANT ENTRY',
  PROCEED = 'PROCEED',
  GUARDED_PROCEED = 'GUARDED PROCEED',
  CHECK_PROCEED = 'CHECK PROCEED',
  CLIENT_ERROR = 'CLIENT ERROR',
  CAMERA_DATA = 'CAMERA DATA',
  TIMESTAMP = 'TIMESTAMP',
  RESPONSE_DATA = 'RESPONSE DATA',
}

export interface CameraData {
  participantId: Participant['participantId'];
  chunk: ArrayBuffer;
  clientTimestamp: Number;
  clientStartTime?: Number;
}
