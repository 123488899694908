import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { AccountCreds } from '../../../Services/api';

import './Login.css';
import { ButtonContainer, Container, Header } from './Login.styled';

export interface LoginErrors {
  username: null | string;
  password: null | string;
  serverError: null | string;
}

interface ILogin {
  errors: LoginErrors;
  handleSubmit: (accountCreds: AccountCreds) => void;
}

export function Login({ errors, handleSubmit }: ILogin): JSX.Element {
  const [creds, setCreds] = React.useState<AccountCreds>({
    username: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreds({ ...creds, [e.target.name]: e.target.value.trim() });
  };

  const onHandleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    handleSubmit(creds);
  };

  return (
    <Container>
      <Form>
        <Header>Login</Header>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={creds.username}
            onChange={handleChange}
            isInvalid={!!errors.username}
          />
          <Form.Control.Feedback type="invalid">
            {errors.username}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            // @ts-ignore
            autocomplete="current-password"
            id="current-password"
            type="password"
            name="password"
            value={creds.password}
            onChange={handleChange}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        ,
        <ButtonContainer>
          <Button variant="primary" type="input" onClick={onHandleSubmit}>
            Submit
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
}
