import * as React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { AccountCreds, API } from '../../../Services/api';
import { Login, LoginErrors } from '../../../Components';

import { AdminContext, useUpdateAlertMessage } from '../../../Context';

import { Container } from './AdminContainer.styled';
// import TokenService from "../../Services/TokenService";
import { RouteGuard } from '../../../GlobalComponents/RouteGuard';
import { AdminDashboard } from '../AdminDashboard';

export function AdminContainer(): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();
  const setAdmin = React.useContext(AdminContext)[1];
  const setAlert = useUpdateAlertMessage();
  const [errors, setErrors] = React.useState<LoginErrors>({} as LoginErrors);

  return (
    <Container>
      <Switch>
        <Route path={`${path}/login`}>
          <Login errors={errors} handleSubmit={handleSubmit} />
        </Route>
        <RouteGuard path={`${path}/sessions`} component={AdminDashboard} />
        <Redirect to={`${path}/sessions`} />
      </Switch>
    </Container>
  );

  async function handleSubmit(accountCreds: AccountCreds) {
    const err = {} as LoginErrors;
    setErrors({} as LoginErrors);
    if (!accountCreds.password.trim()) {
      err.password = 'Password Required';
    }

    if (!accountCreds.username.trim()) {
      err.username = 'Username Required';
    }

    if (!!err.username || !!err.password) {
      setErrors(err);
      return;
    }

    const res = await API.login(accountCreds);

    if (res.error) {
      setAlert({ message: res.error });
    }

    if (res.user) {
      setAdmin({ user: res.user });
      history.push(`${path}/sessions`);
    }
  }
}
