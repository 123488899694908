import { useCallback, useEffect, useRef, useState } from 'react';

import { useParticipantState, useSocket } from 'src/Context';
import { GlobalSocketOnEvents } from 'src/Context/SocketTypes';
import { usePeer } from 'src/hooks';

import { Video, VideoContainer } from './VideoComponent.styled';

declare global {
  interface Window {
    streams: {
      remoteStream: MediaStream | null;
      userStream: MediaStream | null;
    };
  }
}

const CAMERA_DATA = 'CAMERA DATA';
const RECORD_START_TIME = 'RECORD_START_TIME';

interface IVideoComponent {
  userStream: MediaStream;
}

enum SocketOnEvents {
  COMPLETED = 'COMPLETED',
}

export function VideoComponent({ userStream }: IVideoComponent): JSX.Element {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const recorder = useRef<MediaRecorder>(initRecorder());

  const socket = useSocket();
  const participant = useParticipantState();
  const { remoteStream } = usePeer(userStream);
  const [shouldUseRemoteStream, setShouldUseRemoteStream] = useState<boolean>(
    false,
  );

  const handleBeginRecording = useCallback(_handleBeginRecording, [
    socket,
    participant.isTeacher,
  ]);

  useEffect(() => {
    if (videoRef.current?.srcObject === null) {
      setStream({ stream: userStream, isMuted: true });
    }

    if (videoRef.current?.srcObject) {
      if (shouldUseRemoteStream && remoteStream) {
        setStream({ stream: remoteStream, isMuted: false });

        window.streams = {
          userStream,
          remoteStream,
        };
      } else {
        setStream({ stream: userStream, isMuted: true });
      }
    }
  }, [shouldUseRemoteStream, remoteStream, socket, userStream]);

  useEffect(() => {
    const onCompleted = () => {
      socket.stopSocket();

      if (recorder.current) {
        recorder.current.stream.getTracks().forEach((track) => {
          track.stop();
        });

        if (recorder.current.state !== 'inactive') {
          recorder.current.stop();
        }
      }
    };
    socket.on(GlobalSocketOnEvents.BEGIN_RECORDING, handleBeginRecording);
    socket.on(SocketOnEvents.COMPLETED, onCompleted);

    return () => {
      socket.off(SocketOnEvents.COMPLETED, onCompleted);
    };
  }, [socket, handleBeginRecording, participant.isTeacher]);

  return (
    <VideoContainer>
      <Video ref={videoRef} autoPlay />
    </VideoContainer>
  );

  function initRecorder(): MediaRecorder {
    const rec = new MediaRecorder(userStream, {
      audioBitsPerSecond: 64000,
      videoBitsPerSecond: 2000000,
      mimeType: 'video/webm',
    });

    rec.ondataavailable = ({ data }) => {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        if (event.target) {
          socket.emit(CAMERA_DATA, {
            participantId: participant.participantId,
            chunk: event.target.result as ArrayBuffer,
          });
        }
      };
      fileReader.readAsArrayBuffer(data);
    };
    return rec;
  }

  function _handleBeginRecording() {
    if (recorder.current.state !== 'recording') {
      recorder.current.start(5000);
    }
    socket.emit(RECORD_START_TIME, {
      isTeacher: participant.isTeacher,
      startTime: Date.now(),
    });
    setShouldUseRemoteStream(true);
  }

  function setStream({
    stream,
    isMuted,
  }: {
    stream: MediaStream;
    isMuted: boolean;
  }) {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.muted = isMuted;
    }
  }
}
