import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket, useTimer } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  TextContainer,
} from 'src/GlobalComponents';

import { Prompt } from '../../../Prompt';

export function NewInstructions(): JSX.Element {
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const { isTeacher } = useParticipantState();
  const buttonText = isClicked ? 'Please Wait...' : 'Begin';
  const timer = useTimer();
  const socket = useSocket();

  React.useEffect(() => {
    timer.beginInterval('Teaching G2');
  }, [timer]);

  const handleClick = async () => {
    if (isClicked) {
      return;
    }
    setIsClicked(true);
    await timer.getInterval('Teaching G2');
    socket.emitGuardedProceed('GEAR_MACHINE');
  };

  return (
    <Container>
      <ContentContainer>
        <Prompt
          alignment="center"
          message={isTeacher ? '[Read aloud]' : '[Listen]'}
        />
        {isTeacher && (
          <>
            <TextContainer marginTop={10}>
              For the next set of problems, you are to assume that each gear is
              touching two other gears, forming a closed circle.
            </TextContainer>
            <TextContainer marginTop={10}>
              Do you have any questions?
            </TextContainer>
          </>
        )}
      </ContentContainer>
      <FooterContainer>
        <Button disabled={isClicked} onClick={handleClick}>
          {buttonText}
        </Button>
      </FooterContainer>
    </Container>
  );
}
