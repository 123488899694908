import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';

import WellDone from './well_done.png';
import { WellDoneImage } from './NextSection.styled';

export function NextSection(): JSX.Element {
  const { sections } = useParticipantState();

  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const socket = useSocket();

  const handleClick = () => {
    setButtonDisabled(true);
    socket.emitGuardedProceed('MAIN', 'NextSection');
  };

  const scienceFirst = sections[0] === 'science';

  return (
    <Container>
      <HeaderContainer>
        <WellDoneImage src={WellDone} alt="Well Done" />
      </HeaderContainer>
      <ContentContainer verticalCenter>
        <TextContainer alignment="center">
          You have completed the {scienceFirst ? 'Science' : 'Gears'} task.
          Please click the button below when you are ready to begin the{' '}
          {scienceFirst ? 'Gears' : 'Science'} task.
        </TextContainer>
      </ContentContainer>
      <FooterContainer>
        <Button
          variant="primary"
          disabled={buttonDisabled}
          onClick={handleClick}
        >
          Begin Next Task
        </Button>
      </FooterContainer>
    </Container>
  );
}
