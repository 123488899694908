import { assign, Machine } from 'xstate';

export const scienceQuestionMachine = Machine<
  { passageIdx: number },
  {
    type: 'PROCEED_SCIENCE_QUESTION_MACHINE';
  }
>(
  {
    id: 'science-question-machine',
    context: {
      passageIdx: 0,
    },
    initial: 'briefing',
    states: {
      briefing: {
        on: {
          PROCEED_SCIENCE_QUESTION_MACHINE: {
            target: 'content',
          },
        },
      },
      content: {
        on: {
          PROCEED_SCIENCE_QUESTION_MACHINE: {
            target: 'question',
          },
        },
      },
      question: {
        on: {
          PROCEED_SCIENCE_QUESTION_MACHINE: {
            target: 'survey',
          },
        },
      },
      survey: {
        on: {
          PROCEED_SCIENCE_QUESTION_MACHINE: {
            target: 'briefing',
          },
        },
        exit: 'incrementPassageIndex',
      },
    },
  },
  {
    actions: {
      incrementPassageIndex: assign((context) => ({
        passageIdx: context.passageIdx + 1,
      })),
    },
  },
);
