import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket, useTimer } from 'src/Context';

import {
  ContentContainer,
  HeaderContainer,
  FooterContainer,
  Container,
} from 'src/GlobalComponents';

import { Prompt } from '../Prompt';
import example from './example.gif';
import { TextContainer } from './GearBriefing.styled';
import './GearBriefing.css';

export function GearBriefingAbout(): JSX.Element {
  const { isTeacher } = useParticipantState();
  const timer = useTimer();
  const socket = useSocket();

  React.useEffect(() => {
    timer.beginInterval('Teaching G1');
  }, [timer]);

  const handleClick = async () => {
    socket.emitProceed('GEAR_MACHINE');
  };

  return (
    <Container>
      <HeaderContainer>
        <img
          className="example-gears"
          src={example}
          alt="two interlocked gears turning"
        />
      </HeaderContainer>
      <ContentContainer>
        <Prompt
          marginTop={15}
          alignment="center"
          message={isTeacher ? '[Read Aloud]' : '[Listen]'}
        />
        {isTeacher && (
          <TextContainer>
            All of the following problems involve reasoning about gears. I am
            only interested in your answer and do not want you to explain what
            you are thinking. If you have a question about the problem, I will
            only re-read it. Be sure of your answer. After you say your answer
            out loud, I will indicate your response by clicking a button on my
            screen. After that I will tell you whether you are right or wrong
            and then click another button to go on to the next problem.
          </TextContainer>
        )}
      </ContentContainer>
      <FooterContainer>
        {isTeacher && (
          <Button variant="outline-primary" onClick={handleClick}>
            Continue...
          </Button>
        )}
      </FooterContainer>
    </Container>
  );
}

export function GearBriefingRules(): JSX.Element {
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);
  const { isTeacher } = useParticipantState();
  const timer = useTimer();
  const socket = useSocket();

  const handleGuardedProceed = async () => {
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    await timer.getInterval('Teaching G1');
    socket.emitGuardedProceed('GEAR_MACHINE');
  };

  return (
    <Container>
      <HeaderContainer>
        <img
          height={150}
          className="example-gears"
          src={example}
          alt="two interlocked gears turning"
        />
      </HeaderContainer>
      <ContentContainer>
        <Prompt
          alignment="center"
          message={isTeacher ? '[Read Aloud]' : '[Listen]'}
        />
        {isTeacher && (
          <TextContainer>
            <p>For all of the following problems:</p>
            <ul>
              <li>
                Assume that each circle you see in the task represents a gear
                [like the one you see here].
              </li>
              <li>Assume that each gear is touching the gear closest to it.</li>
              <li>
                Assume that for every two gears touching each other, if one is
                turning clockwise, the gear it is touching must be turning
                counter-clockwise, and vice versa.
              </li>
              <li>
                As in the practice, I'm going to ask you a question and then
                click a button that will start a timer. Click the button on your
                screen BEFORE you answer out loud, as soon as you are ready to
                answer.
              </li>
            </ul>
            Would you like me to repeat the rules?
          </TextContainer>
        )}
      </ContentContainer>
      <FooterContainer>
        <Button
          variant="outline-primary"
          onClick={handleGuardedProceed}
          disabled={buttonClicked}
        >
          {buttonClicked ? 'Please Wait...' : 'Begin!'}
        </Button>
      </FooterContainer>
    </Container>
  );
}
