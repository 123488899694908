import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AdminContext } from '../../Context';

interface IRouteGuard {
  component: any;
  path?: string;
  exact?: boolean;
}

export const RouteGuard = ({ component: Component, ...rest }: IRouteGuard) => {
  const admin = React.useContext(AdminContext)[0];

  if (!admin) {
    return <Redirect to={'/admin/login'} />;
  }

  return (
    <Route {...rest}>
      <Component />
    </Route>
  );
};
