import * as React from 'react';

import { IAlertBanner } from '../GlobalComponents';

const AlertUpdateContext = React.createContext<
  React.Dispatch<React.SetStateAction<IAlertBanner>>
>(() => null);
const AlertContext = React.createContext<IAlertBanner>({ message: '' });

export function AlertProvider(props: { children: React.ReactNode }) {
  const [message, setMessage] = React.useState<IAlertBanner>({ message: '' });

  return (
    <AlertUpdateContext.Provider value={setMessage}>
      <AlertContext.Provider value={message}>
        {props.children}
      </AlertContext.Provider>
    </AlertUpdateContext.Provider>
  );
}

export function useAlertMessage() {
  const message = React.useContext(AlertContext);
  if (!message) {
    throw new Error('No message context');
  }
  return message;
}

export function useUpdateAlertMessage() {
  const setMessage = React.useContext(AlertUpdateContext);

  if (!setMessage) {
    throw new Error('No Message setter');
  }
  return setMessage;
}
