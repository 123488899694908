import { MessageContainer } from './Prompt.styled';

interface IPrompt {
  message: string | JSX.Element;
  alignment?: 'left' | 'center';
  size?: 'small' | 'regular';
  marginTop?: number;
}

export function Prompt({
  message,
  size,
  alignment,
  marginTop,
}: IPrompt): JSX.Element {
  return (
    <MessageContainer marginTop={marginTop} size={size} alignment={alignment}>
      {message}
    </MessageContainer>
  );
}
