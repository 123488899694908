import styled from 'styled-components';

export const PortalContainer = styled.div`
  position: absolute;
  z-index: 2;
`;

export const ButtonContainer = styled.span`
  margin: 0px 30px;
  width: 40px;
`;

export const VerticalButtonContainer = styled.div`
  margin: 20px 0px;
`;

export const MCQuestionContainer = styled.div`
  margin: auto;
  width: 650px;
`;

export const VerticalButtonGroup = styled.div`
  text-align: left;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 700px;
  & > span:first-of-type {
    margin-left: 0;
  }
  & > span:last-of-type {
    margin-right: 20px;
  }
`;

export const OverlayContainer = styled.div`
  height: 100vh;
  width: 100vw;
  margin: auto;
  background-color: white;
  text-align: center;
  padding: 50px 100px;

  @media (max-width: 1000px) {
    padding: 100px 20px;
  }
`;

export const ScaleContainer = styled.div`
  margin: auto;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
`;

export const ImagesContainer = styled.div`
  display: flex;
  max-width: 800px;
  flex-wrap: nowrap;
`;

export const ImgContainer = styled.img`
  width: 700px;
  height: 340px;
  // margin-top: -100px;
  // margin-bottom: -150px;
`;

export const TextImageContainer = styled.img`
  width: 100px;
`;

export const WaitContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
  text-align: center;
`;

export const TextContainer = styled.div`
  font-size: 2em;
  margin-top: 200px;
`;

export const H1 = styled.h1`
  margin-bottom: 50px;
`;

export const H2 = styled.h2`
  @media(max-width: 1000px) {
    font-size 1.5em;
  }
`;
