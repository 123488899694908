import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Home } from 'src/Components';
import { ParticipantProvider } from 'src/Context';

import { Container } from './SurveyRoot.styled';
import { SurveyContainer } from '../ExperimentContainer';

export const SurveyRoot = React.memo(
  (): JSX.Element => {
    const { path } = useRouteMatch();

    return (
      <ParticipantProvider>
        <Container>
          <Switch>
            <Route exact path={path}>
              <Home />
            </Route>
            <Route path={`${path}/begin`}>
              <SurveyContainer />
            </Route>
          </Switch>
        </Container>
      </ParticipantProvider>
    );
  },
);
