import { assign, Machine } from 'xstate';

export type GearEvent = {
  type: 'PROCEED_GEAR_QUESTION_MACHINE';
  payload?: string;
};

interface GearQuestionMachine {
  currentQuestionIndex: number;
  studentAnswer: string;
}

export const gearQuestionMachine = (machineId: string) =>
  Machine<GearQuestionMachine, GearEvent>(
    {
      id: machineId,
      initial: 'readProblem',
      context: {
        currentQuestionIndex: 0,
        studentAnswer: '',
      },
      states: {
        readProblem: {
          on: {
            PROCEED_GEAR_QUESTION_MACHINE: {
              target: 'readyToAnswer',
            },
          },
        },
        readyToAnswer: {
          on: {
            PROCEED_GEAR_QUESTION_MACHINE: {
              target: 'answerProblem',
            },
          },
        },
        answerProblem: {
          on: {
            PROCEED_GEAR_QUESTION_MACHINE: {
              target: 'survey',
              actions: ['updateStudentAnswer'],
            },
          },
        },
        survey: {
          on: {
            PROCEED_GEAR_QUESTION_MACHINE: {
              target: 'feedback',
            },
          },
        },
        feedback: {
          on: {
            PROCEED_GEAR_QUESTION_MACHINE: {
              target: 'readProblem',
              actions: ['incrementIndex'],
            },
          },
        },
      },
    },
    {
      actions: {
        incrementIndex: assign((context) => ({
          ...context,
          currentQuestionIndex: context.currentQuestionIndex + 1,
        })),
        updateStudentAnswer: assign((context, e) => ({
          ...context,
          studentAnswer: e.payload,
        })),
      },
    },
  );
