import { useCallback, useEffect, useState, useMemo } from 'react';

import { useParticipantState, useSocket } from 'src/Context';
import { PeerManager } from './PeerManager';

const SIGNAL_DATA = 'SIGNAL_DATA';
const SIGNAL_ERROR = 'SIGNAL_ERROR';
const GUARDED_PEER = 'GUARDED_PEER';

type UsePeer = (
  stream: MediaStream,
) => {
  remoteStream: MediaStream | null;
  isLoading: boolean;
};

export const usePeer: UsePeer = (stream) => {
  const socket = useSocket();
  const { isTeacher, participantId } = useParticipantState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);

  const onReady = useCallback(
    (peerId: string) => {
      socket.emit(GUARDED_PEER, { peerId, isTeacher });
    },
    [socket, isTeacher],
  );

  const onRemoteStream = (stream: MediaStream) => {
    setIsLoading(false);
    setRemoteStream(stream);
  };

  const onError = useCallback(
    (err: any) => {
      socket.emit(SIGNAL_ERROR, { err, participantId });
      setIsLoading(true);
    },
    [participantId, socket],
  );

  const peerManager = useMemo(
    () => PeerManager.getInstance(stream, { onReady, onRemoteStream, onError }),
    [stream, onReady, onError],
  );

  const handleSignalData = useCallback(
    (peerId: string) => {
      if (isTeacher) {
        peerManager.makeCall(peerId, stream);
      }
    },
    [isTeacher, peerManager, stream],
  );

  const handleSignalError = useCallback(
    (error: any) => {
      setIsLoading(true);
      setRemoteStream(null);
      peerManager.forceReset();
    },
    [peerManager],
  );

  useEffect(() => {
    socket.on(SIGNAL_DATA, handleSignalData);
    socket.on(SIGNAL_ERROR, handleSignalError);

    return () => {
      socket.off(SIGNAL_DATA);
      socket.off(SIGNAL_ERROR);
    };
  });

  return {
    remoteStream,
    isLoading,
  };
};
