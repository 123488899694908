import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useParticipantState, useSocket } from 'src/Context';
import { TextContainer } from 'src/GlobalComponents';
import { Overlay, OverlayContainer } from 'src/GlobalComponents/Overlay';
import { disableAudio } from 'src/Helpers';

export function TextOverview(): JSX.Element {
  const [isDisabled, setButtonDisabled] = useState<boolean>(false);
  const { isTeacher } = useParticipantState();
  const socket = useSocket();

  useEffect(() => {
    disableAudio(true);
    return () => disableAudio(false);
  }, []);

  const handleClick = () => {
    setButtonDisabled(true);
    socket.emitGuardedProceed('TEXT_MACHINE');
  };

  return (
    <Overlay>
      <OverlayContainer>
        {isTeacher ? teacherInstructions() : studentInstructions()}
        <Button disabled={isDisabled} onClick={handleClick}>
          Ready To Begin
        </Button>
      </OverlayContainer>
    </Overlay>
  );
}

const studentInstructions = () => {
  return (
    <>
      <TextContainer marginTop={40} alignment="center">
        You are about to interact with your teacher around reading and
        discussing literary texts.
      </TextContainer>
      <TextContainer marginTop={20} alignment="center">
        Throughout the task, there will be some questions to answer by clicking
        a button on screen. Whenever there is no button, your teacher will see a
        button on their screen, and when they click that button, both screens
        will advance.
      </TextContainer>
      <TextContainer marginTop={20} alignment="center">
        You and your teacher will take turns reading texts to one another. Then,{' '}
        <strong>
          your teacher will ask you several open-ended questions about the text,
          please respond honestly and openly to the best of your abilities.
        </strong>
      </TextContainer>
      <TextContainer marginTop={20} marginBottom={50} alignment="center">
        Once both of you click the ‘Ready to Begin’ button below, the task will
        begin.
      </TextContainer>
    </>
  );
};

export const teacherInstructions = () => {
  return (
    <>
      <TextContainer alignment="center" marginTop={40}>
        You are about to interact with your student around reading and
        discussing literary texts.
      </TextContainer>

      <TextContainer marginTop={20} alignment="center">
        In this task, you will take turns reading the text to each other, and
        then you will ask open-ended questions for your student to respond to
        out loud.{' '}
        <strong>
          Please wait for them to finish responding before clicking the button
          to advance.
        </strong>
      </TextContainer>
      <TextContainer marginTop={20} marginBottom={50} alignment="center">
        Once both of you click the ‘Ready to Begin’ button below, the task will
        begin.
      </TextContainer>
    </>
  );
};
