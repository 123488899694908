import styled from 'styled-components';

export const TextContainer = styled.div`
  margin: 40px 10px 0px 0px;
  text-align: left;
  font-size: 1.2em;
`;

export const ResponseContainer = styled.div`
  margin: 10px 10px;
  text-align: center;
  font-size: 1.2em;
`;
