export const data = {
  shortOpen: [
    {
      imageUrl: 'https://i.ibb.co/XkgwQGZ/4gears-clock.png',
      prompt:
        'Four gears are arranged in a line, if you turn gear number 1 clockwise, what will the fourth gear do?',
      answerOptions: ['clockwise', 'counter-clockwise'],
      correctAnswer: 'counter-clockwise',
      type: 'open',
      gearCount: 4,
    },
    // {
    //   imageUrl: 'https://i.ibb.co/88RcbkS/6gears-clock.png',
    //   prompt:
    //     'Six gears are arranged in a line, if you turn gear number 1 clockwise, what will the sixth gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise'],
    //   correctAnswer: 'counter-clockwise',
    //   type: 'open',
    //   gearCount: 6,
    // },
    // {
    //   imageUrl: 'https://i.ibb.co/nfDwDf8/3gears-clock.png',
    //   prompt:
    //     'Three gears are arranged in a line, if you turn gear number 1 clockwise, what will the third gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise'],
    //   correctAnswer: 'clockwise',
    //   type: 'open',
    //   gearCount: 3,
    // },
  ],
  longOpen: [
    {
      imageUrl: 'https://i.ibb.co/DVJvmm2/15gears-clock.png',
      prompt:
        'Fifteen gears are arranged in a line, if you turn gear number 1 clockwise, what will the fifteenth gear do?',
      answerOptions: ['clockwise', 'counter-clockwise'],
      correctAnswer: 'clockwise',
      type: 'open',
      gearCount: 15,
    },
    // {
    //   imageUrl: 'https://i.ibb.co/phQBW5h/10gears-clock.png',
    //   prompt:
    //     'ten gears are arranged in a line, if you turn gear number 1 clockwise, what will the tenth gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise'],
    //   correctAnswer: 'counter-clockwise',
    //   type: 'open',
    //   gearCount: 10,
    // },
    // {
    //   imageUrl: 'https://i.ibb.co/vVnqWK3/8gears-clock.png',
    //   prompt:
    //     'Eight gears are arranged in a line, if you turn gear number 1 clockwise, what will the eighth gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise'],
    //   correctAnswer: 'counter-clockwise',
    //   type: 'open',
    //   gearCount: 8,
    // },
  ],
  shortClosed: [
    // {
    //   imageUrl: 'https://i.ibb.co/Bj2Fw0y/c3gears-counter.png',
    //   prompt:
    //     'three gears are arranged in a circle, if you turn gear number 1 counter-clockwise, what will the third gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise', 'locked'],
    //   correctAnswer: 'locked',
    //   type: 'closed',
    //   gearCount: 3,
    // },
    {
      imageUrl: 'https://i.ibb.co/jbV9KdH/c4gears-counter.png',
      prompt:
        'Four gears are arranged in a circle, if you turn gear number 1 counter-clockwise, what will the fourth gear do?',
      answerOptions: ['clockwise', 'counter-clockwise', 'locked'],
      correctAnswer: 'clockwise',
      type: 'closed',
      gearCount: 4,
    },
    // {
    //   imageUrl: 'https://i.ibb.co/G7GM1Jn/c5gears-counter.png',
    //   prompt:
    //     'Five gears are arranged in a circle, if you turn gear number 1 counter-clockwise, what will the fifth gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise', 'locked'],
    //   correctAnswer: 'locked',
    //   type: 'closed',
    //   gearCount: 5,
    // },
  ],
  longClosed: [
    {
      imageUrl: 'https://i.ibb.co/ZBHZSBn/c10gears-counter.png',
      prompt:
        'Ten gears are arranged in a circle, if you turn gear number 1 counter-clockwise, what will the tenth gear do?',
      answerOptions: ['clockwise', 'counter-clockwise', 'locked'],
      correctAnswer: 'clockwise',
      type: 'closed',
      gearCount: 10,
    },
    // {
    //   imageUrl: 'https://i.ibb.co/0GqyP6j/c9gears-counter.png',
    //   prompt:
    //     'Nine gears are arranged in a circle, if you turn gear number 1 counter-clockwise, what will the ninth gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise', 'locked'],
    //   correctAnswer: 'locked',
    //   type: 'closed',
    //   gearCount: 9,
    // },
    // {
    //   imageUrl: 'https://i.ibb.co/Mk4Q9yw/c14gears-clock.png',
    //   prompt:
    //     'Fourteen gears are arranged in a circle, if you turn gear number 1 clockwise, what will the fourteenth gear do?',
    //   answerOptions: ['clockwise', 'counter-clockwise', 'locked'],
    //   correctAnswer: 'counter-clockwise',
    //   type: 'closed',
    //   gearCount: 14,
    // },
  ],
  textQuestions: [
    {
      header: 'The Things They Carried - Stories and Memory Pg. 36-38',

      sections: [
        `What sticks to memory, often, are those odd little fragments that have no beginning or end:
        Norman Bowker lying on his back one night, watching the stars, then whispering to me, "I'll tell you something, O'Brien. If I could have one wish, anything, I'd wish for my dad to write me a letter and say it's okay if I don't win any medals. That's all my old man talks about, nothing else. How he can't wait to see my goddamn medals."`,
        `Or Kiowa teaching a rain dance to Rat Kiley and Dave Jensen, the three of them whooping and leaping around barefoot while a bunch of villagers looked on with a mixture of fascination and giggly horror. Afterward, Rat said, "So where's the rain?" and Kiowa said, "The earth is slow, but the buffalo is patient," and Rat thought about it and said, "Yeah, but where's the rain?"`,
        `Or Ted Lavender adopting an orphan puppy--feeding it from a plastic spoon and carrying it in his rucksack until the day Azar strapped it to a Claymore antipersonnel mine and squeezed the firing device.`,
      ],
      questions: [
        {
          prompt: 'Norman Bowker talked about:',
          choices: ['Medals', 'Grenades', 'Sending letters home'],
          correctAnswer: 'Medals',
        },
        {
          prompt: 'Ted lavender adopted:',
          choices: ['A kitten', 'A rabbit', 'A puppy'],
          correctAnswer: 'A puppy',
        },
        {
          prompt: 'Or Kiowa taught a rain dance to Rat Kiley and:',
          choices: ['Norman Bowker', 'Dave Jenson', 'Ted Lavender'],
          correctAnswer: 'Dave Jensen',
        },
      ],
    },

    {
      header: 'The Things They Carried - Stories and Memory Pg. 36-38',

      sections: [
        `The average age in our platoon, I'd guess, was nineteen or twenty, and as a consequence things often took on a curiously playful atmosphere, like a sporting event at some exotic reform school. The competition could be lethal, yet there was a childlike exuberance to it all, lots of pranks and horseplay. Like when Azar blew away Ted Lavender's puppy. "What's everybody so upset about?" Azar said. "I mean, Christ, I'm just a boy."`,
        `I remember these things, too. 
  
        The damp, fungal scent of an empty body bag.
      
        A quarter moon rising over the night time paddies.
      
      Henry Dobbins sitting in the twilight, sewing on his new buck-sergeant stripes, quietly singing, "A tisket, a tasket, a green, and yellow basket."
      
      A field of elephant grass weighted with wind, bowing under the stir of a helicopter's blades, the grass dark and servile, bending low, but then rising straight again when the chopper went away.
      
      A red clay trail outside the village of My Khe.
      
      A hand grenade.
      
      A slim, dead, dainty young man of about twenty.
      
      Kiowa saying, "No choice, Tim. What else could you do?"
      
      Kiowa saying, "Right?"
      
      Kiowa saying, "Talk to me."`,
        `Forty-three years old, and the war occurred half a life-time ago, and yet the remembering makes it now. And sometimes remembering will lead to a story, which makes it forever. That's what stories are for. Stories are for joining the past to the future. Stories are for those late hours in the night when you can't remember how you got from where you were to where you are. Stories are for eternity, when memory is erased, when there is nothing to remember except the story.`,
      ],
      questions: [
        {
          prompt: 'Azar defended his actions by claiming he was just:',
          choices: ['A soldier', 'In a story', 'A boy'],
          correctAnswer: 'A boy',
        },
        {
          prompt: 'Which weapon does the narrator remember?',
          choices: ['A hand grenade', 'A land mine', 'Sarin gas'],
          correctAnswer: 'A hand grenade',
        },
        {
          prompt: 'Who tells the narrator, “Talk to me.”?',
          choices: ['Ted Lavender', 'Henry Dobbins', 'Or Kiowa'],
          correctAnswer: 'Or Kiowa',
        },
      ],
    },
    {
      header: 'The Things They Carried - What is a true war story? Pg. 80-82',

      sections: [
        `War is hell, but that’s not the half of it, because war is also mystery and terror and adventure and courage and discovery and holiness and pity and despair and longing and love. War is nasty; war is fun. War is thrilling; war is drudgery. War makes you a man; war makes you dead.`,
        `The truths are contradictory. It can be argued, for instance, that war is grotesque. But in truth war is also beauty. For all its horror, you can't help but gape at the awful majesty of combat. You stare out at tracer rounds unwinding through the dark like brilliant red ribbons. You crouch in ambush as a cool impassive moon rises over the nighttime paddies. You admire the fluid symmetries of troops on the move, the harmonies of sound and shape and proportion, the great sheets of metal-fire streaming down from a gunship, the illumination rounds, the white phosphorous, the purply orange glow of napalm, the rocket's red glare.`,
        `It's not pretty, exactly. It's astonishing. It fills the eye. It commands you. You hate it, yes, but your eyes do not. Like a killer forest fire, like cancer under a microscope, any battle or bombing raid or artillery barrage has the aesthetic purity of absolute moral indifference--a powerful, implacable beauty--and a true war story will tell the truth about this, though the truth is ugly.`,
      ],
      questions: [
        {
          prompt: 'The truths are:',
          choices: ['False', 'Contradictory', 'War is hell'],
          correctAnswer: 'Contradictory',
        },
        {
          prompt: 'The narrator clains that a true war story:',
          choices: [
            'Is beautiful',
            'Tells the truth',
            'Tells a beautifully ugly truth',
          ],
          correctAnswer: 'Tells a beautifully ugly truth',
        },
        {
          prompt: 'The narrator claims that battle or a raid has:',
          choices: [
            'Aesthetic beauty',
            'Aesthetic purity',
            'Absolute moral indifference',
          ],
          correctAnswer: 'Absolute moral indifference',
        },
      ],
    },
    {
      header: 'The Things They Carried - What is a true war story? Pg. 80-82',
      sections: [
        `To generalize about war is like generalizing about peace. Almost everything is true. Almost nothing is true. At its core, perhaps, war is just another name for death, and yet any soldier will tell you, if he tells the truth, that proximity to death brings with it a corresponding proximity to life. After a firefight, there is always the immense pleasure of aliveness, The trees are alive. The grass, the soil--everything. All around you things are purely living, and you among them, and the aliveness makes you tremble. You feel an intense, out-of- the-skin awareness of your living self--your truest self, the human being you want to be and then become by the force of wanting it.`,
        `In the midst of evil you want to be a good man. You want decency. You want justice and courtesy and human concord, things you never knew you wanted. There is a kind of largeness to it, a kind of godliness. Though it's odd, you're never more alive than when you're almost dead. You recognize what's valuable. Freshly, as if for the first time, you love what's best in yourself and in the world, all that might be lost. At the hour of dusk you sit at your foxhole and look out on a wide river turning pinkish red, and at the mountains beyond, and although in the morning you must cross the river and go into the mountains and do terrible things and maybe die, even so, you find yourself studying the fine colors on the river, you feel wonder and awe at the setting of the sun, and you are filled with a hard, aching love for how the world could be and always should be, but now is not.`,
        `Mitchell Sanders was right. For the common soldier, at least, war has the feel--the spiritual texture--of a great ghostly fog, thick and permanent. There is no clarity. Everything swirls. The old rules are no longer binding, the old truths no longer true. Right spills over into wrong. Order blends into chaos, love into hate, ugliness into beauty, law into anarchy, civility into savagery. The vapors suck you in. You can't tell where you are, or why you're there, and the only certainty is overwhelming ambiguity.
      
      In war you lose your sense of the definite, hence your sense of truth itself, and therefore it's safe to say that in a true war story nothing is ever absolutely true.`,
      ],

      questions: [
        {
          prompt: 'War is just another name for:',
          choices: ['Death', 'Truth', 'Peace'],
          correctAnswer: 'Death',
        },
        {
          prompt:
            'The narrator claims that loving what is best in yourself comes from:',
          choices: ['Seeing colors in a river', 'War', 'Almost death'],
          correctAnswer: 'Almost death',
        },
        {
          prompt: 'What was Mitchell Sanders right about?',
          choices: ['The fog', 'War', 'Truth'],
          correctAnswer: 'War',
        },
      ],
    },
  ],
};
