import styled from 'styled-components';

export const VideoContainer = styled.div`
  background-color: black;
  flex: 1;
  min-width: 400px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 1200px) {
    flex: 1;
  }
`;

export const Video = styled.video`
  width: 100%;
`;

export const MiniVideo = styled.video`
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid red;
  max-width: 300px;

  @media only screen and (max-width: 1000px) {
    position: static;
    height: unset;
    width: 100%;
  }
`;
