import * as React from 'react';

export interface MCResponse {
  participantId: string;
  isCorrect: boolean;
  createdAt: string;
}

export interface GearResponse {
  isCorrect: boolean;
  count: number;
  type: 'open' | 'closed';
  created_at: string;
}

export interface SurveyResponse {
  participantId: string;
  response: string;
  surveyType: string;
  createdAt: string;
  surveyCode: string;
}

export interface Intervals {
  participantId: string;
  eventName: string;
  atClientTime: number;
  interval: number;
}

export interface SessionData {
  studentId: string;
  teacherId: string;
}

export type SessionDataState = SessionData | null;
type StateSessionContext = [SessionDataState, (s: SessionDataState) => void];

export const SessionContext = React.createContext<StateSessionContext>([
  null,
  () => null,
]);
