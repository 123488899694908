import { useImage } from 'src/hooks/useImage';

interface ImageProps {
  fileName: string;
  alt: string;
  className: string;
}
export const ImageViewer = ({ fileName, alt, className }: ImageProps) => {
  const { loading, error, image } = useImage(fileName);

  if (error) return <p>{`Error loading image: ${fileName}`}</p>;

  return (
    <>
      {loading ? (
        <p>loading...</p>
      ) : (
        <img className={className} src={image} alt={alt} />
      )}
    </>
  );
};
