import * as React from 'react';
import Button from 'react-bootstrap/Button';

import {
  ExperimentSections,
  useParticipantState,
  useSocket,
} from 'src/Context';
import {
  Container,
  ContentContainer,
  HeaderContainer,
  FooterContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { Overlay } from 'src/GlobalComponents/Overlay';

import cameraCheckWrong from './cameraCheck1.png';
import cameraCheckCorrect from './cameraCheck2.png';
import { H2, InstructionsContainer } from './ExperimentBriefing.styled';

const PARTICIPANT_READY = 'PARTICIPANT READY';

export function ExperimentBriefing(): JSX.Element {
  const [showOverlay, setShowOverlay] = React.useState<boolean>(false);
  const handleClick = () => setShowOverlay(true);

  return (
    <Container headerType="small">
      {showOverlay && <Instructions />}
      <HeaderContainer>
        <h3>Camera Check Checkpoint</h3>
      </HeaderContainer>
      <ContentContainer>
        <TextContainer marginTop={20} alignment="center">
          Please align your body and head to your camera as in the model below.
        </TextContainer>
        <div style={{ margin: 'auto' }}>
          <img
            src={cameraCheckCorrect}
            alt="Position yourself with your face in front of camera"
            style={{ width: '40%' }}
          />
          <div>
            <img
              src={cameraCheckWrong}
              alt="Position yourself with your face in front of camera"
              style={{ width: '40%', marginTop: 10 }}
            />
          </div>
        </div>
      </ContentContainer>
      <FooterContainer>
        <Button variant="outline-primary" onClick={handleClick}>
          Continue
        </Button>
      </FooterContainer>
    </Container>
  );
}

function Instructions(): JSX.Element {
  const [isDisabled, setIsdisabled] = React.useState<boolean>(false);
  const [buttonText, setButtonText] = React.useState<string>('Ready to Begin');
  const participant = useParticipantState();
  const socket = useSocket();

  const handleClick = () => {
    setIsdisabled(true);
    setButtonText('Please Wait...');
    socket.emit(PARTICIPANT_READY, participant);
    socket.emitGuardedProceed('MAIN', 'Experiment Briefing');
  };

  return (
    <Overlay>
      <InstructionsContainer>
        <H2>
          <u>Read Everything Below CAREFULLY</u>
        </H2>
        <TextContainer fontSize={1} alignment="center">
          You are about to interact with your{' '}
          {participant.isTeacher ? 'student' : 'teacher'} around{' '}
          {numbersToWords[participant.sections.length]} teaching &amp; learning
          tasks:
        </TextContainer>
        <TextContainer fontSize={1} alignment="center">
          {generateExperimentSectionsList(participant.sections)}
        </TextContainer>
        <TextContainer marginTop={20} fontSize={1} alignment="center">
          Throughout the interaction, there will be some questions to answer by
          clicking a button on screen. Whenever there is no button, your{' '}
          {participant.isTeacher ? 'student' : 'teacher'} will see a button on
          their screen, and when they click that button, both screens will
          advance.
        </TextContainer>
        <TextContainer
          marginBottom={30}
          marginTop={20}
          fontSize={1}
          alignment="center"
        >
          {isDisabled
            ? 'Please wait for the other participant.'
            : 'Once both of you click the ‘Ready to Begin’ button below, a short practice task will begin.'}
        </TextContainer>
        <Button disabled={isDisabled} onClick={handleClick}>
          {buttonText}
        </Button>
      </InstructionsContainer>
    </Overlay>
  );
}

const generateExperimentSectionsList = (sections: ExperimentSections[]) => {
  const sectionList = sections.map((section, idx) => (
    <li key={section} style={{ listStyle: 'none' }}>{`${idx + 1}) ${
      experimentMessageMap[section]
    }`}</li>
  ));

  return <ul>{sectionList}</ul>;
};

const experimentMessageMap = {
  gears: 'Solving problems related to chains of gears',
  text: 'Reading and discussing literary texts',
  science:
    'Designing experiments, generating hypotheses, and interpreting data',
};

const numbersToWords: { [key: number]: string } = {
  1: 'one',
  2: 'two',
  3: 'three',
};
