import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ButtonContainer } from 'src/Containers/Admin/Login/Login.styled';

import { useParticipantState, useSocket } from 'src/Context';
import { TextContainer } from 'src/GlobalComponents';
import { Overlay, OverlayContainer } from 'src/GlobalComponents/Overlay';
import { showRemoteStream } from 'src/Helpers';

export function GearOverview(): JSX.Element {
  const [isDisabled, setButtonDisabled] = useState<boolean>(false);
  const { isTeacher } = useParticipantState();
  const socket = useSocket();

  useEffect(() => {
    return () => showRemoteStream();
  }, []);

  const handleClick = () => {
    setButtonDisabled(true);
    socket.emitGuardedProceed('GEAR_MACHINE');
  };

  return (
    <Overlay>
      <OverlayContainer>
        {isTeacher ? teacherInstructions() : studentInstructions()}
        <ButtonContainer>
          <Button disabled={isDisabled} onClick={handleClick}>
            Ready To Begin
          </Button>
        </ButtonContainer>
      </OverlayContainer>
    </Overlay>
  );
}

const studentInstructions = () => {
  return (
    <>
      <TextContainer marginTop={50} alignment="center">
        You are about to interact with your teacher around solving problems
        related to gears.
      </TextContainer>
      <TextContainer marginTop={20} alignment="center">
        In this task, you will need to click a button when you are ready to
        answer the problem.
      </TextContainer>
      <TextContainer marginTop={20} alignment="center">
        <strong>
          Your performance will be judged for both speed and accuracy. Make sure
          you <u>click the button as soon as</u> you are ready to answer{' '}
          <u>before you answer out loud.</u>
        </strong>
      </TextContainer>
      <TextContainer marginTop={20} marginBottom={50} alignment="center">
        Once both of you click the ‘Ready to Begin’ button below, the task will
        begin.
      </TextContainer>
    </>
  );
};

const teacherInstructions = () => {
  return (
    <>
      <TextContainer alignment="center" marginTop={50}>
        You are about to interact with your student around solving problems
        related to gears.
      </TextContainer>
      <TextContainer alignment="center" marginTop={20}>
        In this task, your student will be instructed to verbally respond to the
        gear problems. You will need to click the button indicating their
        response.
      </TextContainer>
      <TextContainer marginTop={20} alignment="center">
        <strong>
          Please click the button indicating the response they say out loud
        </strong>{' '}
        (e.g. ‘Clockwise’ or ‘Counter clockwise’) and do not click the button
        for a response they did not say out loud.
      </TextContainer>
      <TextContainer marginTop={20} marginBottom={50} alignment="center">
        Once both of you click the ‘Ready to Begin’ button below, the task will
        begin.
      </TextContainer>
    </>
  );
};
