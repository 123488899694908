const TOKEN_KEY = 'token';
export const TokenService = {
  saveAuthToken(token: string) {
    window.localStorage.setItem(TOKEN_KEY, token);
  },
  getAuthToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  },
  clearToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  },
  hasAuthToken() {
    return !!TokenService.getAuthToken();
  },
};

export default TokenService;
