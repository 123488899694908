import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useSocket, useTimer } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { disableAudio } from 'src/Helpers';

import congrats from './congrats.gif';
import { CongratsGif, H2 } from './Exit.styled';
import { GoodbyePage } from './GoodbyePage';

enum SocketOnEvents {
  FINAL = 'FINAL',
}

enum SocketEmitEvents {
  COMPLETE = 'COMPLETE',
  FINAL = 'FINAL',
}

export function Exit(): JSX.Element {
  const participant = useParticipantState();
  const isTeacher = participant?.isTeacher;
  const [showGoodbye, setShowGoodbye] = React.useState(false);
  const socket = useSocket();
  const timer = useTimer();

  React.useEffect(() => {
    timer.beginInterval('Farewell');
  }, [timer]);

  React.useEffect(() => {
    if (isTeacher && showGoodbye) {
      setTimeout(() => socket.emit(SocketEmitEvents.COMPLETE), 5200);
    }
  }, [isTeacher, showGoodbye, socket, timer]);

  const finish = React.useCallback(async () => {
    await timer.getInterval('Farewell');
    socket.emit(SocketEmitEvents.FINAL);
  }, [socket, timer]);

  const onFinal = () => {
    disableAudio(true);
    setShowGoodbye(true);
  };

  React.useEffect(() => {
    socket.on(SocketOnEvents.FINAL, onFinal);

    return () => {
      socket.off(SocketOnEvents.FINAL, onFinal);
    };
  }, [showGoodbye, socket]);

  return (
    <Container>
      {showGoodbye && <GoodbyePage />}
      <HeaderContainer>
        <CongratsGif src={congrats} alt="Congratuations!" />
      </HeaderContainer>
      <ContentContainer verticalCenter>
        <div>
          <H2>That’s All Folks!</H2>
          <TextContainer alignment="center">
            Well done, thank you SO much for participating, and please say
            farewell and anything more you would like to say to your{' '}
            {isTeacher ? 'student' : 'teacher'} before clicking the button
            below.
          </TextContainer>
        </div>
      </ContentContainer>
      <FooterContainer>
        <Button variant="primary" onClick={finish}>
          Exit Room
        </Button>
      </FooterContainer>
    </Container>
  );
}
