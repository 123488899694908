import { useMachine } from '@xstate/react';
import * as React from 'react';
import { assign, Machine } from 'xstate';

import { QuestionBank, useSocket } from 'src/Context';

import './GearMachine.css';
import {
  ExplainParity,
  ExplainReasoning,
  GearOverview,
  NewInstructions,
} from './Components';
import { GearBriefingAbout, GearBriefingRules } from '../GearBriefing';
import { GearQuestionViews } from './GearQuestionViews';
import { CameraCheckpoint } from '../CameraCheckpoint';

enum SocketEmitEvents {
  GET_QUESTIONS = 'GET QUESTIONS',
}

enum SocketOnEvents {
  RECEIVE_QUESTIONS = 'RECEIVE QUESTIONS',
  PROCEED_GEAR_MACHINE = 'PROCEED GEAR_MACHINE',
}

type ComponentNames =
  | 'cameraCheckpoint'
  | 'overview'
  | 'about'
  | 'rules'
  | 'explainReasoning'
  | 'explainReasoning2'
  | 'newInstructions'
  | 'explainParity';

const components: { [key in ComponentNames]: React.FC } = {
  cameraCheckpoint: () => <CameraCheckpoint target="GEAR_MACHINE" />,
  overview: GearOverview,
  about: GearBriefingAbout,
  rules: GearBriefingRules,
  explainReasoning: () => <ExplainReasoning />,
  explainReasoning2: () => <ExplainReasoning count={2} />,
  newInstructions: NewInstructions,
  explainParity: ExplainParity,
};

export function GearMachine(): JSX.Element {
  const socket = useSocket();
  const [state, send] = useMachine(gearMachine);

  const [questionBank, setQuestionBank] = React.useState<null | QuestionBank>(
    null,
  );

  React.useEffect(() => {
    socket.on(SocketOnEvents.RECEIVE_QUESTIONS, (questionData: string) => {
      if (!questionData) {
        console.log('No question data');
      }
      if (!questionBank) {
        setQuestionBank(JSON.parse(questionData));
      }
    });
    socket.on(SocketOnEvents.PROCEED_GEAR_MACHINE, () =>
      send('PROCEED_GEAR_MACHINE'),
    );

    if (!questionBank) {
      socket.emit(SocketEmitEvents.GET_QUESTIONS);
    }
    return () => {
      socket.off(SocketOnEvents.PROCEED_GEAR_MACHINE);
      socket.off(SocketOnEvents.RECEIVE_QUESTIONS);
    };
  }, [send, socket, questionBank]);

  const Component = React.useMemo(() => {
    if (state.value === 'gearQuestionMachineOpen') {
      return () => (
        <GearQuestionViews
          key="open-gears"
          machineId={'gear-question-machine-open'}
          questions={[...questionBank!.longOpen]}
          initialCount={0}
        />
      );
    }

    if (state.value === 'gearQuestionMachineClosed') {
      return () => (
        <GearQuestionViews
          key="closed-gears"
          machineId={'gear-question-machine-closed'}
          questions={[...questionBank!.longClosed]}
          initialCount={4}
        />
      );
    }
    return components[state.value as ComponentNames];
  }, [state.value, questionBank]);

  if (!questionBank) {
    return <div>Loading...</div>;
  }

  return <Component />;
}

const gearMachine = Machine<any, { type: 'PROCEED_GEAR_MACHINE' }>({
  id: 'gearMachine',
  initial: 'cameraCheckpoint',
  context: {
    component: 'cameraCheckpoint',
  },
  states: {
    cameraCheckpoint: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'overview',
          actions: [
            assign({
              component: 'overview',
            }),
          ],
        },
      },
    },
    overview: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'about',
          actions: [
            assign({
              component: 'about',
            }),
          ],
        },
      },
    },
    about: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'rules',
          actions: [
            assign({
              component: 'rules',
            }),
          ],
        },
      },
    },
    rules: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'gearQuestionMachineOpen',
        },
      },
    },
    gearQuestionMachineOpen: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'explainReasoning',
          actions: [
            assign({
              component: 'explainReasoning',
            }),
          ],
        },
      },
    },
    explainReasoning: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'newInstructions',
          actions: [
            assign({
              component: 'newInstructions',
            }),
          ],
        },
      },
    },
    newInstructions: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'gearQuestionMachineClosed',
        },
      },
    },
    gearQuestionMachineClosed: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'explainReasoning2',
          actions: [
            assign({
              component: 'explainReasoning',
            }),
          ],
        },
      },
    },
    explainReasoning2: {
      on: {
        PROCEED_GEAR_MACHINE: {
          target: 'explainParity',
          actions: [
            assign({
              component: 'explainParity',
            }),
          ],
        },
      },
    },
    explainParity: {
      type: 'final',
    },
  },
});
