import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useSocket } from 'src/Context';

import { AnswerButtonContainer } from './AnswerButtonGroup.styled';

enum SocketOnEvents {
  ANSWER_SELECTION = 'ANSWER SELECTION',
}

export function AnswerButtonGroup() {
  const socket = useSocket();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setButtonDisabled(true);
    socket.emit(
      SocketOnEvents.ANSWER_SELECTION,
      e.currentTarget.textContent!.toLowerCase(),
    );
  };

  return (
    <AnswerButtonContainer>
      <div>
        <Button
          disabled={buttonDisabled}
          size="lg"
          variant="outline-primary"
          onClick={handleClick}
        >
          Left
        </Button>
      </div>
      <div>
        <Button
          disabled={buttonDisabled}
          size="lg"
          variant="outline-primary"
          onClick={handleClick}
        >
          Right
        </Button>
      </div>
    </AnswerButtonContainer>
  );
}
