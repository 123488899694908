import * as React from 'react';

//@ts-ignore
import { Timer } from 'interval-timer';

import { useParticipantState } from './ParticipantContext';
import { AppSocketManager, useSocket } from './SocketContext';
import { logger } from 'src/Helpers';

const INTERVAL_START_TIME = 'INTERVAL_START_TIME';

export type BeginInterval = (
  intervalName: string,
  keepInterval: boolean,
) => void;

export type GetInterval = (intervalName: string) => void;

export const TimerContext = React.createContext<Timer>({} as Timer);

class ImportedTimer {
  private timer: Timer;
  private participantId: string;
  private sessionId: string;
  private static timerInstance: Timer;
  private socket: AppSocketManager;
  private event: string;
  private debug: boolean;

  constructor(
    socket: AppSocketManager,
    participantId: string,
    sessionId: string,
  ) {
    this.timer = new Timer({ updateFrequency: 10 });
    this.participantId = participantId;
    this.sessionId = sessionId;
    this.socket = socket;
    this.event = '';
    this.debug = false;
  }

  public beginInterval(intervalName: string, keepInterval: boolean = true) {
    this.timer.start();
    const startTime = this.timer._timeAtStart;
    if (this.event) {
      logger(
        `called with ${intervalName}, but with event already set to: ${this.event}`,
        { debugEnabled: this.debug, level: 'error' },
      );
    } else {
      if (keepInterval) {
        this.event = intervalName;
      } else {
        this.timer.reset();
      }
    }

    this.socket.emit(INTERVAL_START_TIME, {
      participant_id: this.participantId,
      session_id: this.sessionId,
      event_name: intervalName,
      interval_start_time: startTime,
      at_client_time: Date.now(),
    });
  }

  public async getInterval(eventName: string) {
    const { millisecondsTotal } = this.timer.getTime;
    this.timer.reset();
    if (this.debug) {
      console.log({
        interval: millisecondsTotal,
        currentInterval: this.event,
        eventName,
      });
    }
    this.event = '';
    await this._emitIntervalData(eventName, millisecondsTotal);
  }

  private async _emitIntervalData(eventName: string, interval: number) {
    await this.socket.emitIntervalData({
      eventName,
      interval,
      participantId: this.participantId,
      atClientTime: Date.now(),
    });
  }

  public static getInstance(
    socket: AppSocketManager,
    participantId: string,
    sessionId: string,
  ) {
    if (!ImportedTimer.timerInstance) {
      ImportedTimer.timerInstance = new ImportedTimer(
        socket,
        participantId,
        sessionId,
      );
    }
    return Timer.timerInstance;
  }
}

export function TimerProvider(props: { children: React.ReactNode }) {
  const socket = useSocket();
  const { participantId } = useParticipantState();
  const sessionId = participantId.split('_')[0].slice(0, -1);

  const timer = new ImportedTimer(socket, participantId, sessionId);

  return (
    <TimerContext.Provider value={timer}>
      {props.children}
    </TimerContext.Provider>
  );
}

export function useTimer(): ImportedTimer {
  const timer = React.useContext(TimerContext);
  if (!timer) {
    throw Error('Not in Timer Context');
  }
  return timer;
}
