import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  margin: auto;
  margin-top: 40px;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 5px;
`;
export const Header = styled.h1`
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
`;
export const ButtonContainer = styled.div`
  text-align: center;
`;
