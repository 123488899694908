import { useEffect } from 'react';

import { useParticipantState, useSocket } from 'src/Context';
import { TextBrowser, TextContainer } from 'src/GlobalComponents';
import { Overlay, OverlayContainer } from 'src/GlobalComponents/Overlay';
import { showRemoteStream } from 'src/Helpers';

export function ScienceOverview(): JSX.Element {
  const socket = useSocket();
  const { isTeacher } = useParticipantState();

  useEffect(() => {
    return () => showRemoteStream();
  }, []);

  const handleFinalClick = (): void => {
    socket.emitGuardedProceed('SCIENCE_MACHINE');
  };

  return (
    <Overlay>
      <OverlayContainer>
        <TextBrowser
          onFinalClick={handleFinalClick}
          content={isTeacher ? teacherMessages : studentMessages}
        />
      </OverlayContainer>
    </Overlay>
  );
}

const studentMessages = [
  <>
    <TextContainer marginTop={0} alignment="center">
      <b>Read all instructions carefully</b>
      <br />
      <br />
      You are about to interact with your teacher to:
    </TextContainer>
    <br />
    <br />
    <TextContainer alignment="left">
      <ol>
        <li>design an experimental solution in response to a given problem</li>
        <li>generate hypotheses</li>
        <li>interpret a set of data.</li>
      </ol>
    </TextContainer>
  </>,
  <TextContainer marginTop={0} alignment="center">
    <b>Read all instructions carefully</b>
    <br />
    <br />
    <b>Please respond OUT LOUD to the best of your abilities.</b>
    <br />
    <br />
    <b>We're interested in all of your thoughts. Please think out loud.</b>
    <br />
    <br />
    Once both of you click the ‘Ready to Begin’ button below, the task will
    begin.
  </TextContainer>,
];

const teacherMessages = [
  <>
    {' '}
    <TextContainer marginTop={0} alignment="center">
      <b>Read all instructions carefully</b>
      <br />
      <br />
      You are about to interact with your student by asking them to:
    </TextContainer>
    <TextContainer marginTop={20} alignment="left" marginLeft={25}>
      <ul>
        <li> design an experimental solution in response to a given problem</li>
        <li> generate hypotheses to explain an experimental outcome, and</li>
        <li> interpret data found by another experiment.</li>
      </ul>
    </TextContainer>
  </>,
  <TextContainer marginTop={0} alignment="center">
    <b>Read all instructions carefully</b>
    <br />
    <br />
    In the following task, you will ask your student questions out loud, and
    they will respond. Once you are done reading each question, click "Done
    Reading".
  </TextContainer>,
  <TextContainer marginTop={0} alignment="center">
    <b>Read all instructions carefully</b>
    <br />
    <br />
    Once both of you click the ‘Ready to Begin’ button below, the task will
    begin.
  </TextContainer>,
];
