import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useMediaStream } from 'src/hooks';
import { VideoComponent } from 'src/Components';
import {
  Participant,
  QuestionProvider,
  TimerProvider,
  useParticipantState,
} from 'src/Context';

import { Container } from './ExperimentContainer.styled';
import { QuestionContainer } from '../QuestionContainer';

export function SurveyContainer(): JSX.Element {
  const participant = useParticipantState();

  if (!participant.participantId) {
    return <Redirect to={'/'} />;
  }

  return (
    <QuestionProvider>
      <Container>
        {participant.useMedia ? (
          <MediaWrapper participant={participant} />
        ) : (
          <></>
        )}
        <TimerProvider>
          <QuestionContainer sections={participant.sections} />
        </TimerProvider>
      </Container>
    </QuestionProvider>
  );
}

function MediaWrapper({ participant }: { participant: Participant }) {
  const { isLoading, mediaStream } = useMediaStream({
    withVideo: participant.videoEnabled,
  });

  useEffect(() => {
    if (!isLoading && mediaStream) {
      stopBrowserBack();
    }
  }, [isLoading, mediaStream]);

  if (isLoading || !mediaStream) {
    return <div>Loading...</div>;
  }

  return <VideoComponent userStream={mediaStream} />;
}

const stopBrowserBack = () => {
  window.history.pushState(null, '', window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, '', window.location.href);
  };
};
