import styled from 'styled-components';

export const H2 = styled.h2`
  margin-bottom: 40px;
  text-align: 'center';
`;

export const TextContainer = styled.div`
  margin-top: 20px;
  text-align: left;
  margin: 20px 10px 10px;
  font-size: 1.1em;
`;

export const OverlayContainer = styled.div`
  height: 100vh;
  width: 100vw;
  margin: auto;
  background-color: white;
  text-align: center;
  padding: 10px 200px;
`;

export const CongratsGif = styled.img`
  height: 100%;
`;
