import { useMachine } from '@xstate/react';
import * as React from 'react';
import { assign, Machine } from 'xstate';

import { QuestionBank, useSocket } from 'src/Context';
import { TextBriefing, TextOverview } from './Components';
import { TextQuestionViews } from './TextQuestionsViews';
import { SurveyView } from 'src/GlobalComponents/Overlay/SurveyOverlay';

enum SocketEmitEvents {
  GET_QUESTIONS = 'GET QUESTIONS',
}

enum SocketOnEvents {
  RECEIVE_QUESTIONS = 'RECEIVE QUESTIONS',
  PROCEED_TEXT_MACHINE = 'PROCEED TEXT_MACHINE',
}

type ComponentNames =
  | 'overview'
  | 'textBriefing'
  | 'textQuestionMachine'
  | 'survey';

const components: { [key in ComponentNames]: React.FC } = {
  overview: TextOverview,
  textBriefing: TextBriefing,
  textQuestionMachine: TextQuestionViews,
  survey: () => <SurveyView emitTo={'MAIN'} section="text" />,
};

export function TextMachine(): JSX.Element {
  const socket = useSocket();
  const [state, send] = useMachine(textMachine);

  const [questionBank, setQuestionBank] = React.useState<null | QuestionBank>(
    null,
  );

  React.useEffect(() => {
    socket.on(SocketOnEvents.RECEIVE_QUESTIONS, (questionData: string) => {
      if (!questionData) {
        console.log('Problem getting data');
      }
      if (!questionBank) {
        setQuestionBank(JSON.parse(questionData));
      }
    });
    socket.on(SocketOnEvents.PROCEED_TEXT_MACHINE, () =>
      send('PROCEED_TEXT_MACHINE'),
    );

    if (!questionBank) {
      socket.emit(SocketEmitEvents.GET_QUESTIONS);
    }
    return () => {
      socket.off(SocketOnEvents.RECEIVE_QUESTIONS);
      socket.off(SocketOnEvents.PROCEED_TEXT_MACHINE);
    };
  }, [questionBank, send, socket]);

  const Component = components[state.value as ComponentNames];

  if (!questionBank) {
    return <div>Loading...</div>;
  }

  return <Component />;
}

const textMachine = Machine<any, { type: 'PROCEED_TEXT_MACHINE' }>({
  id: 'textMachine',
  initial: 'overview',
  context: {
    component: 'overview',
  },
  states: {
    overview: {
      on: {
        PROCEED_TEXT_MACHINE: {
          target: 'textBriefing',
          actions: [
            assign({
              component: 'textBriefing',
            }),
          ],
        },
      },
    },
    textBriefing: {
      on: {
        PROCEED_TEXT_MACHINE: {
          target: 'textQuestionMachine',
          actions: [
            assign({
              component: 'textQuestionMachine',
            }),
          ],
        },
      },
    },
    textQuestionMachine: {
      on: {
        PROCEED_TEXT_MACHINE: {
          target: 'survey',
          actions: [
            assign({
              component: 'survey',
            }),
          ],
        },
      },
    },
    survey: {
      type: 'final',
    },
  },
});
