import * as React from 'react';
import Button from 'react-bootstrap/Button';

import {
  ScienceQuestion,
  useParticipantState,
  useSocket,
  useTimer,
} from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { Prompt } from '../../../Prompt';

interface IThoughtQuestions {
  passageIndex: number;
  passage: ScienceQuestion;
}

export function ThoughtQuestions({ passageIndex, passage }: IThoughtQuestions) {
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const { isTeacher } = useParticipantState();
  const timer = useTimer();
  const socket = useSocket();

  const intervalName = React.useMemo(() => `SR-${passageIndex + 1}`, [
    passageIndex,
  ]);

  React.useEffect(() => {
    timer.beginInterval(intervalName, isTeacher);
  }, [intervalName, isTeacher, timer]);

  const handleNext = async () => {
    await timer.getInterval(intervalName);
    setButtonDisabled(true);
    socket.emitProceed('SCIENCE_QUESTION_MACHINE');
  };

  return (
    <Container>
      <HeaderContainer>
        <Prompt
          alignment="center"
          message={isTeacher ? '[Read Aloud]' : '[Listen & Respond]'}
        />
      </HeaderContainer>
      <ContentContainer>
        {isTeacher ? (
          <>
            <TextContainer alignment="center" marginTop={20}>
              {thoughtQuestions[passageIndex]}
            </TextContainer>
            <Prompt
              marginTop={50}
              alignment="center"
              message="[Wait for Response then click]"
            />
          </>
        ) : (
          <TextContainer>{passage.sections[0]}</TextContainer>
        )}
      </ContentContainer>
      {isTeacher && (
        <FooterContainer>
          <Button disabled={buttonDisabled} onClick={handleNext}>
            Continue
          </Button>
        </FooterContainer>
      )}
    </Container>
  );
}

const thoughtQuestions = [
  'Given the problem, design an experiment to help investigate possible solutions to the problem.',
  'Given the experiment, formulate a hypothesis the experiment is designed to test.',
  'Given the results of the experiment, how do you interpret the engineers’ hypothesis?',
  'Given the whole experiment, what limitations of its design would you seek to improve upon in future directions?',
];
