import { AppSocketManager } from 'src/Context';

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const disableAudio = (isMuted: boolean) => {
  const video = document.querySelector('video');
  if (video) {
    video.muted = isMuted;
  }
};

// hacky as fuck :(
export const showUserStream = () => {
  const video = document.querySelector('video');
  if (video && window.streams) {
    video.muted = true;
    video.srcObject = window.streams.userStream;
  }
};

export const showRemoteStream = () => {
  const video = document.querySelector('video');

  if (video && window.streams) {
    video.muted = false;
    video.srcObject = window.streams.remoteStream;
  }
};

interface LogData {
  additionalInfo?: any;
  level?: string;
  debugEnabled?: boolean;
}

export const logger = (message: any, logData?: LogData) => {
  if (logData?.debugEnabled) {
  } else {
    const socket = AppSocketManager.getInstance();
    socket.connectedLogger(message, {
      additionalInfo: handleCaughtErrors(logData?.additionalInfo),
      level: logData?.level,
    });
  }
};

export const handleCaughtErrors = (e: any): string | number => {
  if (e) {
    if (typeof e === 'object') {
      if ('message' in e) {
        return e.message;
      } else {
        return JSON.stringify(e);
      }
    } else if (typeof e === 'string' || typeof e === 'number') {
      return e;
    }
  }
  return 'No additional info';
};
