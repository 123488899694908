import styled from 'styled-components';

type HeaderType = 'none' | 'small' | 'regular';

interface IContentContainer {
  verticalCenter?: boolean;
}

export const Container = styled.div<{ headerType?: HeaderType }>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${({ headerType }) =>
    headerType === 'regular'
      ? '35% 50% 15%'
      : headerType === 'small'
      ? '5% 80% 15%'
      : '85% 15%'};
  height: 100%;
  grid-template-areas: ${({ headerType }) =>
    headerType !== 'none' ? gridWithHeader : gridWithoutHeader}
  } 
`;

const gridWithHeader = `
"header"
"content"
"footer"
`;
const gridWithoutHeader = `
"content"
"footer"
`;

export const HeaderContainer = styled.div`
  grid-area: header;
`;

export const ContentContainer = styled.div<IContentContainer>`
  grid-area: content;
  display: ${({ verticalCenter }) => (verticalCenter ? 'flex' : 'block')};
  align-items: center;
  overflow-y: auto;
  height: 100%;
`;

export const FooterContainer = styled.div`
  grid-area: footer;
  align-items: start;
`;

Container.defaultProps = {
  headerType: 'regular',
};
ContentContainer.defaultProps = {
  verticalCenter: false,
};
