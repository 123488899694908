export const getPromptText = (
  isTeacher: boolean,
  teacherControlsButton: boolean,
) => {
  if (isTeacher) {
    return teacherControlsButton ? '[Read aloud]' : '[Listen]';
  } else {
    return teacherControlsButton ? '[Listen]' : '[Respond]';
  }
};
