import { useEffect, useState } from 'react';
import { TextContainer } from 'src/GlobalComponents';

export const Timer = () => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    } else {
      startBrowserBack();
    }
  }, [counter]);

  return counter ? (
    <TextContainer color="red" fontSize={1} alignment={'center'}>
      <b>{`Finalizing... hang on for ${counter} second${
        counter === 1 ? '' : 's'
      }!`}</b>
    </TextContainer>
  ) : (
    <></>
  );
};

const startBrowserBack = () => {
  // @ts-ignore
  window.onpopstate = undefined;
  window.history.back();
};
