import { useEffect } from 'react';

import { useParticipantState, useSocket, useTimer } from 'src/Context';
import { TextBrowser, TextContainer } from 'src/GlobalComponents';

import { Prompt } from '../../../Prompt';

export function ScienceBriefing(): JSX.Element {
  const timer = useTimer();
  const socket = useSocket();
  const { isTeacher } = useParticipantState();

  const handleFinalClick = async () => {
    await timer.getInterval('Teaching SR');
    socket.emitProceed('SCIENCE_MACHINE');
  };

  useEffect(() => {
    timer.beginInterval('Teaching SR', isTeacher);
  }, [isTeacher, timer]);

  return (
    <>
      <Prompt
        alignment="center"
        message={isTeacher ? '[Read Aloud]' : '[Listen]'}
      />
      {isTeacher && (
        <TextBrowser
          onFinalClick={handleFinalClick}
          content={teacherMessages}
        />
      )}
    </>
  );
}

const teacherMessages = [
  <TextContainer>
    For this task, I will provide information relevant to questions about
    scientific reasoning. For the first set of questions, I will present a
    problem for investigation. For the second set I will ask you to generate
    hypotheses and for the third sets of questions, I will present data and
    findings for interpretation.
  </TextContainer>,
  <TextContainer>
    Please only click the ‘Done Answering’ buttons after you have completely
    answered the questions and are satisfied with your answer. Do not click the
    ‘Done Answering’ button before you have answered the questions.
    <br />
    <br />
    Once you click the ‘Done Answering’ button, we will move on to the next
    question and you will not be able to respond to the current question any
    more.
    <br />
    <br />
    Would you like me to repeat these instructions?
  </TextContainer>,
];
