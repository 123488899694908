import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';

import { GearResponse } from 'src/Context';
import { API } from 'src/Services/api';

interface IGearResponsesTable {
  studentId: string;
}

export function GearResponsesTable({
  studentId,
}: IGearResponsesTable): JSX.Element {
  const params = useParams<{ sessionId: string }>();
  const [gearResponses, setGearResponses] = React.useState<GearResponse[]>([]);

  const fetchGearResponses = React.useCallback(async () => {
    const data: GearResponse[] = await API.session.getGearResponses(
      params.sessionId,
    );

    setGearResponses(data);
  }, [params.sessionId]);

  React.useEffect(() => {
    fetchGearResponses();
  }, [fetchGearResponses]);

  return (
    <BTable striped bordered hover>
      <thead>
        <tr>
          <th>Participant #</th>
          <th>Problem</th>
          <th>Accuracy</th>
        </tr>
      </thead>
      <tbody>
        {gearResponses.map((g, idx) => (
          <tr key={idx}>
            <td>{studentId}</td>
            <td>Gear {idx + 1}</td>
            <td>{g.isCorrect}</td>
          </tr>
        ))}
      </tbody>
    </BTable>
  );
}
