import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { GetInterval, useSocket } from 'src/Context';

import { AnswerButtonContainer } from './AnswerButtonGroup.styled';

interface IAnswerButtonGroup {
  getInterval: GetInterval;
  showLockedButton?: boolean;
}

enum SocketOnEvents {
  ANSWER_SELECTION = 'ANSWER SELECTION',
}

export function AnswerButtonGroup({
  getInterval,
  showLockedButton,
}: IAnswerButtonGroup) {
  const socket = useSocket();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setButtonDisabled(true);
    getInterval('Response');
    socket.emit(
      SocketOnEvents.ANSWER_SELECTION,
      e.currentTarget.textContent!.toLowerCase(),
    );
  };

  return (
    <AnswerButtonContainer>
      <div>
        <Button
          disabled={buttonDisabled}
          size="lg"
          variant="outline-primary"
          onClick={handleClick}
        >
          Clockwise
        </Button>
      </div>
      {showLockedButton && (
        <div>
          <Button
            disabled={buttonDisabled}
            size="lg"
            variant="outline-primary"
            onClick={handleClick}
          >
            Locked
          </Button>
        </div>
      )}
      <div>
        <Button
          disabled={buttonDisabled}
          size="lg"
          variant="outline-primary"
          onClick={handleClick}
        >
          Counter-Clockwise
        </Button>
      </div>
    </AnswerButtonContainer>
  );
}
