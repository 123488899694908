import moment from 'moment';
import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';

import { SurveyResponse } from 'src/Context';
import { API } from 'src/Services/api';

interface ISurveyTableData {
  createdAt: string;
  unpleasant?: string;
  pleasant?: string;
  participantId: string;
  participantType: string;
  surveyCode: string;
}

export function SurveyResponsesTable({
  studentId,
}: {
  studentId: string;
}): JSX.Element {
  const params = useParams<{ sessionId: string }>();
  const [surveyResponses, setSurveyResponses] = React.useState<
    ISurveyTableData[]
  >([]);

  const fetchSurveyResponses = React.useCallback(async () => {
    const data: SurveyResponse[] = await API.session.getSurveyResponses(
      params.sessionId,
    );

    const mappedData = data
      .reduce((prev, curr) => {
        const existingSurvey = prev.find(
          (s) => s.surveyCode === curr.surveyCode,
        );

        if (existingSurvey) {
          if (existingSurvey.pleasant) {
            existingSurvey.unpleasant = curr.response;
          } else {
            existingSurvey.pleasant = curr.response;
          }
        } else {
          prev.push({
            createdAt: curr.createdAt,
            unpleasant:
              curr.surveyType === 'unpleasant' ? curr.response : undefined,
            pleasant:
              curr.surveyType === 'pleasant' ? curr.response : undefined,
            participantId: curr.participantId,
            participantType:
              curr.participantId === studentId ? 'Student' : 'Teacher',
            surveyCode: curr.surveyCode,
          });
        }

        return prev;
      }, [] as ISurveyTableData[])
      .sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
      );
    setSurveyResponses(mappedData);
  }, [params.sessionId, studentId]);

  React.useEffect(() => {
    fetchSurveyResponses();
  }, [fetchSurveyResponses]);

  return (
    <BTable striped bordered hover>
      <thead>
        <tr>
          <th>Participant #</th>
          <th>Participant Type</th>
          <th>Survey #</th>
          <th>Unpleasant Value</th>
          <th>Pleasant Value</th>
        </tr>
      </thead>
      <tbody>
        {surveyResponses.map((s, idx) => {
          let surveyCount = 1;
          if (idx >= 2) {
            surveyCount = Math.floor(idx / 2) + 1;
          }

          return (
            <tr key={idx}>
              <td>{s.participantId}</td>
              <td>{s.participantType}</td>
              <td>{surveyCount}</td>
              <td>{s.unpleasant}</td>
              <td>{s.pleasant}</td>
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
}
