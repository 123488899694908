import { useMachine } from '@xstate/react';
import * as React from 'react';
import { useParticipantState, useSocket } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { SurveyView } from 'src/GlobalComponents/Overlay/SurveyOverlay';
import { capitalize } from 'src/Helpers';

import { Prompt } from '../Prompt';
import './DemoMachine.css';
import { ButtonController, DemoFeedback } from './Components';
import { demoQuestionMachine } from './DemoQuestionMachine';

import leftArrow from './left_arrow.jpg';
import rightArrow from './right_arrow.jpg';

interface IAnswerProblem {
  isTeacher: boolean;
  prompt: string;
}

interface IReadyToAnswer {
  isTeacher: boolean;
  prompt: string;
}

interface IReadProblemView {
  isTeacher: boolean;
  prompt: string;
}

enum SocketOnEvents {
  PROCEED_DEMO_QUESTION_MACHINE = 'PROCEED DEMO_QUESTION_MACHINE',
  SELECTED_ANSWER = 'SELECTED ANSWER',
}

export function DemoQuestionViews(): JSX.Element {
  const socket = useSocket();
  const { isTeacher } = useParticipantState();

  const [buttonsDisabled, setButtonsDisabled] = React.useState<boolean>(false);

  const [state, send] = useMachine(demoQuestionMachine());
  const currentQuestionIndex = state.context.currentQuestionIndex;
  const currentQuestion = questions[currentQuestionIndex];

  const disableButtons = () => {
    setButtonsDisabled(true);
    const buttonTimer = setTimeout(() => setButtonsDisabled(false), 1000);
    return () => clearTimeout(buttonTimer);
  };

  React.useEffect(() => {
    socket.on(SocketOnEvents.PROCEED_DEMO_QUESTION_MACHINE, () =>
      send('PROCEED_DEMO_QUESTION_MACHINE'),
    );
    socket.on(SocketOnEvents.SELECTED_ANSWER, (answer: string) =>
      send({ type: 'PROCEED_DEMO_QUESTION_MACHINE', payload: answer }),
    );
    return () => {
      socket.off(SocketOnEvents.PROCEED_DEMO_QUESTION_MACHINE);
      socket.off(SocketOnEvents.SELECTED_ANSWER);
    };
  }, [send, socket]);

  if (!currentQuestion) {
    return <></>;
  }

  return (
    <Container>
      <HeaderContainer>
        <img
          className="question-image"
          src={currentQuestion!.imageUrl}
          alt="gears"
        />
      </HeaderContainer>
      <ContentContainer>
        {
          {
            readProblem: (
              <ReadProblemView
                isTeacher={isTeacher}
                prompt={currentQuestion!.prompt}
              />
            ),
            readyToAnswer: (
              <ReadyToAnswer
                isTeacher={isTeacher}
                prompt={currentQuestion!.prompt}
              />
            ),
            answerProblem: (
              <AnswerProblem
                isTeacher={isTeacher}
                prompt={currentQuestion!.prompt}
              />
            ),
            survey: <SurveyView isDemo emitTo={'DEMO_QUESTION_MACHINE'} />,
            feedback: (
              <DemoFeedback
                isTeacher={isTeacher}
                answer={state.context.studentAnswer}
                correctAnswer={currentQuestion!.correctAnswer}
              />
            ),
          }[state.value as string]
        }
      </ContentContainer>
      <FooterContainer>
        <ButtonController
          buttonsDisabled={buttonsDisabled}
          setButtonsDisabled={disableButtons}
          state={state.value as string}
          isTeacher={isTeacher}
          isLastQuestion={!questions[currentQuestionIndex + 1]}
        />
      </FooterContainer>
    </Container>
  );
}

function ReadProblemView({ isTeacher, prompt }: IReadProblemView): JSX.Element {
  return (
    <>
      <Prompt
        marginTop={40}
        alignment="center"
        message={isTeacher ? '[Read aloud]' : '[Listen]'}
      />
      {!isTeacher && (
        <Prompt
          marginTop={40}
          alignment="center"
          message={"[DON'T RESPOND YET]"}
        />
      )}
      {isTeacher && (
        <>
          <TextContainer marginTop={20}>{prompt}</TextContainer>
          <Prompt marginTop={20} alignment="center" message="[now click]" />
        </>
      )}
    </>
  );
}

function ReadyToAnswer({ isTeacher, prompt }: IReadyToAnswer): JSX.Element {
  return (
    <>
      <Prompt
        alignment="center"
        marginTop={100}
        message={
          isTeacher ? (
            "[Wait for student's response]"
          ) : (
            <TextContainer fontSize={1} alignment="center">
              [click <u>Before</u> responding]
            </TextContainer>
          )
        }
      />
    </>
  );
}

function AnswerProblem({ isTeacher, prompt }: IAnswerProblem): JSX.Element {
  const splitText = prompt.split('. ');
  const text = capitalize(splitText[1]);

  return (
    <>
      {!isTeacher && (
        <TextContainer marginTop={50} alignment="center">
          {text}
        </TextContainer>
      )}
      <br />
      <br />
      <Prompt
        alignment="center"
        message={
          isTeacher ? "[Click student's response]" : '[Respond out loud]'
        }
      />
    </>
  );
}

const prompt =
  'In the picture above, there is an arrow. Which direction is the arrow pointing?';

const questions = [
  {
    imageUrl: leftArrow,
    prompt,
    answerOptions: ['Left', 'Right'],
    correctAnswer: 'left',
  },
  {
    imageUrl: rightArrow,
    prompt,
    answerOptions: ['Left', 'Right'],
    correctAnswer: 'right',
  },
];
