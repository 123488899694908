import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useSocket } from 'src/Context';
import {
  Container,
  ContentContainer,
  HeaderContainer,
  FooterContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { showUserStream } from 'src/Helpers';

import cameraCheckWrong from './cameraCheck1.png';
import cameraCheckCorrect from './cameraCheck2.png';

type Target = 'GEAR_MACHINE' | 'SCIENCE_MACHINE';

export function CameraCheckpoint({ target }: { target: Target }): JSX.Element {
  const [isDisabled, setIsdisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Continue');
  const socket = useSocket();

  useEffect(() => {
    showUserStream();
  }, []);

  const handleClick = () => {
    setIsdisabled(true);
    setButtonText('Please Wait...');
    socket.emitGuardedProceed(target, 'Camera Checkpoint');
  };

  return (
    <Container headerType="small">
      <HeaderContainer>
        <h3>Camera Check Checkpoint</h3>
      </HeaderContainer>
      <ContentContainer>
        <TextContainer marginTop={20} alignment="center">
          Please align your body and head to your camera as in the model below.
        </TextContainer>
        <div style={{ margin: 'auto' }}>
          <img
            src={cameraCheckCorrect}
            alt="Position yourself with your face in front of camera"
            style={{ width: '40%' }}
          />
          <div>
            <img
              src={cameraCheckWrong}
              alt="Position yourself with your face in front of camera"
              style={{ width: '40%', marginTop: 10 }}
            />
          </div>
        </div>
      </ContentContainer>
      <FooterContainer>
        <Button
          disabled={isDisabled}
          variant="outline-primary"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </FooterContainer>
    </Container>
  );
}
