import { useMachine } from '@xstate/react';
import { useEffect, useMemo } from 'react';
import { assign, Machine } from 'xstate';

import { devController } from 'src/Config';
import { Participant, useSocket } from 'src/Context';

import {
  DemoMachine,
  Exit,
  ExperimentBriefing,
  GearMachine,
  GreetingComponent,
  NextSection,
  TextMachine,
  ScienceMachine,
} from './Components';

import { Container } from './QuestionContainer.styled';

enum SocketOnEvents {
  PROCEED_MAIN = 'PROCEED MAIN',
}

export function QuestionContainer({
  sections,
}: {
  sections: Participant['sections'];
}): JSX.Element {
  const generatedMachine = useMemo(() => generateMasterMachine(sections), [
    sections,
  ]);

  const [state, send] = useMachine(generatedMachine);
  const socket = useSocket();

  useEffect(() => {
    socket.on(SocketOnEvents.PROCEED_MAIN, () => send('PROCEED_MAIN'));
  }, [send, socket]);

  const Component = components[state.context.component as componentNames];

  return (
    <Container>
      <Component />
    </Container>
  );
}

const components = {
  introduction: ExperimentBriefing,
  demo: DemoMachine,
  nextSection: NextSection,
  greeting: GreetingComponent,
  gearMachine: GearMachine,
  textMachine: TextMachine,
  scienceMachine: ScienceMachine,
  exit: Exit,
};

type componentNames =
  | 'introduction'
  | 'demo'
  | 'greeting'
  | 'nextSection'
  | 'gearMachine'
  | 'textMachine'
  | 'scienceMachine'
  | 'exit';

const generateMasterMachine = (sections: Participant['sections']) => {
  const experimentSections = generateExperimentSections(sections);

  return Machine({
    id: 'views',
    initial: 'introduction',
    context: {
      component: 'introduction',
    },
    states: {
      introduction: {
        on: {
          PROCEED_MAIN: {
            target: 'greeting',
            actions: [
              assign({
                component: 'greeting',
              }),
            ],
          },
        },
      },
      greeting: {
        on: {
          PROCEED_MAIN: {
            target: !devController.enablePractice
              ? sectionToMachineMap[sections[0]]
              : 'demo',
            actions: [
              assign({
                component: !devController.enablePractice
                  ? sectionToMachineMap[sections[0]]
                  : 'demo',
              }),
            ],
          },
        },
      },
      demo: {
        on: {
          PROCEED_MAIN: {
            target: sectionToMachineMap[sections[0]],
            actions: [
              assign({
                component: sectionToMachineMap[sections[0]],
              }),
            ],
          },
        },
      },
      ...experimentSections,
      exit: {
        type: 'final',
      },
    },
  });
};

function generateExperimentSections(
  experimentSections: Participant['sections'],
): any {
  const sections = [...experimentSections];

  if (!sections.length) {
    console.log('Cannot create a null experiment');
    throw new Error('Cannot create a null experiment');
  }

  const currentSection = sections.shift();

  if (!sections.length) {
    return {
      [sectionToMachineMap[currentSection!]]: {
        on: {
          PROCEED_MAIN: {
            target: 'exit',
            actions: [
              assign({
                component: 'exit',
              }),
            ],
          },
        },
      },
    };
  }

  return {
    [sectionToMachineMap[currentSection!]]: {
      on: {
        PROCEED_MAIN: {
          target: 'nextSection',
          actions: [
            assign({
              component: 'nextSection',
            }),
          ],
        },
      },
    },
    nextSection: {
      on: {
        PROCEED_MAIN: {
          target: [sectionToMachineMap[sections[0]]],
          actions: [
            assign({
              component: [sectionToMachineMap[sections[0]]],
            }),
          ],
        },
      },
    },
    ...(sections.length && generateExperimentSections(sections)),
  };
}

const sectionToMachineMap = {
  gears: 'gearMachine',
  text: 'textMachine',
  science: 'scienceMachine',
};
