import Peer, { MediaConnection } from 'peerjs';
import { logger } from 'src/Helpers';

const debugEnabled = false;

interface PeerManagerCallbacks {
  onReady: (peerId: string) => void;
  onRemoteStream: (stream: MediaStream) => void;
  onError: (error: any) => void;
}
export class PeerManager {
  private peer: Peer | null = null;
  private isLoaded: boolean = false;
  private static instance: PeerManager | null = null;
  private callbacks: PeerManagerCallbacks = {
    onReady: (peerId: string) => null,
    onRemoteStream: (stream: MediaStream) => null,
    onError: (error: any) => null,
  };

  public static getInstance(
    stream: MediaStream,
    callbacks: PeerManagerCallbacks,
  ) {
    if (!PeerManager.instance) {
      PeerManager.instance = new PeerManager(stream, callbacks);
    }
    PeerManager.instance._init(stream, callbacks);
    return PeerManager.instance;
  }
  private constructor(
    private stream: MediaStream,
    callbacks: PeerManagerCallbacks,
  ) {
    this._init(stream, callbacks);
  }

  public _init(stream?: MediaStream, callbacks?: PeerManagerCallbacks) {
    this.stream = stream ?? this.stream;
    this.callbacks = callbacks ?? this.callbacks;

    if (!this.peer) {
      this.peer = new Peer();
      logger('creating peer', { debugEnabled });
    }
    this.peer.removeAllListeners();
    this.peer.on('call', this._receiveCall.bind(this));
    this.peer.on('error', this._handleError.bind(this));
    this.peer.on('open', this._handleOpen.bind(this));
  }

  makeCall(peerId: string, stream: MediaStream) {
    if (this.isLoaded) {
      this._callPeer(peerId, stream);
    }
  }

  forceReset() {
    this._reset();
  }

  private _callPeer(peerId: string, stream: MediaStream) {
    if (this.peer && this.isLoaded) {
      const call = this.peer.call(peerId, stream);
      this._configureCall(call);
    }
  }

  private _handleOpen(id: string) {
    this.isLoaded = true;
    this.callbacks.onReady(id);
  }

  private _receiveCall(call: MediaConnection) {
    call.answer(this.stream);
    this._configureCall(call);
  }

  private _configureCall(call: MediaConnection) {
    call.on('stream', (remoteUserStream) => {
      this._addCustomErrorHandler(call);
      this.callbacks.onRemoteStream(remoteUserStream);
      logger('Connection established', { debugEnabled });
    });

    call.on('error', (err: any) => {
      this._handleError(err);
    });
  }

  private _addCustomErrorHandler(call: MediaConnection) {
    if (call.peerConnection) {
      call.peerConnection.onconnectionstatechange = (ev: Event) => {
        if (
          // @ts-ignore
          ev.currentTarget?.connectionState === 'failed' ||
          // @ts-ignore
          ev.currentTarget?.connectionState === 'disconnected'
        ) {
          this._handleError('Connection Problem');
        }
      };
    }
  }

  private _reset() {
    this.peer?.removeAllListeners();
    this.peer?.destroy();
    this.peer = null;
    this.isLoaded = false;
    this._init();
  }

  private _handleError(err: any) {
    this.callbacks.onError(err);
    logger(`Error creating peer connection`, {
      debugEnabled,
      additionalInfo: err,
      level: 'error',
    });
  }
}

// const config = {
//   iceServers: [
//     {
//       urls: 'stun:us-turn6.xirsys.com',
//     },
//     {
//       urls: 'turn:us-turn6.xirsys.com:80?transport=udp',
//       username:
//         'Srx-qIv5b8Z5ipt80F8lg08krPGjZcmj7XrJjw8scrhU5oMsWjOhmgQKBts2dNlvAAAAAGKFbohqYW1zdGVyMTA=',
//       credential: '25c563f4-d6f7-11ec-8c64-0242ac140004',
//     },
//     {
//       urls: 'turn:us-turn6.xirsys.com:3478?transport=udp',
//       username:
//         'Srx-qIv5b8Z5ipt80F8lg08krPGjZcmj7XrJjw8scrhU5oMsWjOhmgQKBts2dNlvAAAAAGKFbohqYW1zdGVyMTA=',
//       credential: '25c563f4-d6f7-11ec-8c64-0242ac140004',
//     },
//     {
//       urls: 'turn:us-turn6.xirsys.com:80?transport=tcp',
//       username:
//         'Srx-qIv5b8Z5ipt80F8lg08krPGjZcmj7XrJjw8scrhU5oMsWjOhmgQKBts2dNlvAAAAAGKFbohqYW1zdGVyMTA=',
//       credential: '25c563f4-d6f7-11ec-8c64-0242ac140004',
//     },
//     {
//       urls: 'turn:us-turn6.xirsys.com:3478?transport=tcp',
//       username:
//         'Srx-qIv5b8Z5ipt80F8lg08krPGjZcmj7XrJjw8scrhU5oMsWjOhmgQKBts2dNlvAAAAAGKFbohqYW1zdGVyMTA=',
//       credential: '25c563f4-d6f7-11ec-8c64-0242ac140004',
//     },
//     // {
//     //   urls: 'turns:us-turn6.xirsys.com:443?transport=tcp',
//     //   username:
//     //     'Srx-qIv5b8Z5ipt80F8lg08krPGjZcmj7XrJjw8scrhU5oMsWjOhmgQKBts2dNlvAAAAAGKFbohqYW1zdGVyMTA=',
//     //   credential: '25c563f4-d6f7-11ec-8c64-0242ac140004',
//     // },
//     // {
//     //   urls: 'turns:us-turn6.xirsys.com:5349?transport=tcp',
//     //   username:
//     //     'Srx-qIv5b8Z5ipt80F8lg08krPGjZcmj7XrJjw8scrhU5oMsWjOhmgQKBts2dNlvAAAAAGKFbohqYW1zdGVyMTA=',
//     //   credential: '25c563f4-d6f7-11ec-8c64-0242ac140004',
//     // },
//   ],
// };

// const config2 = {
//   iceServers: [
//     {
//       urls: 'stun:openrelay.metered.ca:80',
//     },
//     {
//       urls: 'turn:openrelay.metered.ca:80',
//       username: 'openrelayproject',
//       credential: 'openrelayproject',
//     },
//     {
//       urls: 'turn:openrelay.metered.ca:443',
//       username: 'openrelayproject',
//       credential: 'openrelayproject',
//     },
//     {
//       urls: 'turn:openrelay.metered.ca:443?transport=tcp',
//       username: 'openrelayproject',
//       credential: 'openrelayproject',
//     },
//   ],
// };
