import styled from 'styled-components';

export const Container = styled.div`
  max-width: 700px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 5px;
  text-align: center;
`;
export const Header = styled.h3`
  margin: auto;
  margin-bottom: 10px;
`;
export const InformationContainer = styled.div`
  text-align: center;
  margin: 20px 0px 40px;
`;
