import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { useParticipantState, useTimer } from 'src/Context';
import {
  Container,
  ContentContainer,
  FooterContainer,
  TextContainer,
} from 'src/GlobalComponents';
import { Prompt } from '../../../Prompt';

interface IThoughtQuestions {
  passageIndex: number;
  nextPassage: () => void;
}

export function ThoughtQuestions({
  nextPassage,
  passageIndex,
}: IThoughtQuestions) {
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState<number>(0);
  const { isTeacher } = useParticipantState();
  const timer = useTimer();

  const isSecondPassage = passageIndex % 2 !== 0;
  const questions = isSecondPassage
    ? thoughtQuestions
    : thoughtQuestions.slice(0, 3);

  const intervalName = React.useMemo(() => {
    if (currentQuestion === questions.length - 1) {
      return isSecondPassage
        ? `CQ ${passageIndex === 1 ? '1' : '2'}`
        : `TQ ${passageIndex + 1}-${currentQuestion + 1}`;
    } else {
      return `TQ ${passageIndex + 1}-${currentQuestion + 1}`;
    }
  }, [currentQuestion, isSecondPassage, passageIndex, questions.length]);

  React.useEffect(() => {
    if (buttonDisabled) {
      const buttonTimer = setTimeout(() => setButtonDisabled(false), 500);
      return () => clearTimeout(buttonTimer);
    }
  }, [buttonDisabled]);

  React.useEffect(() => {
    timer.beginInterval(intervalName, isTeacher);
  }, [currentQuestion, intervalName, isTeacher, timer]);

  const handleNext = async () => {
    timer.getInterval(intervalName);
    if (currentQuestion === questions.length - 1) {
      nextPassage();
    } else {
      setButtonDisabled(true);
      setCurrentQuestion((q) => q + 1);
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Prompt
          alignment="center"
          message={isTeacher ? '[Read Aloud]' : '[Listen]'}
        />
        {isTeacher && (
          <>
            <TextContainer alignment="center" marginTop={20}>
              {questions[currentQuestion]}
            </TextContainer>
            <Prompt
              marginTop={50}
              alignment="center"
              message="[Wait for Response then click]"
            />
          </>
        )}
      </ContentContainer>
      {isTeacher && (
        <FooterContainer>
          <Button disabled={buttonDisabled} onClick={handleNext}>
            {currentQuestion === questions.length - 1
              ? 'Continue'
              : 'Next Question'}
          </Button>
        </FooterContainer>
      )}
    </Container>
  );
}

const thoughtQuestions = [
  'How does this text make you feel?',
  'What do you think the author meant to say with this text?',
  'What from the text makes you think that is what the author meant to say?',
  'How do these last two texts relate to each other, the real world, or your own life?',
];
