import React from 'react';
import Button from 'react-bootstrap/Button';
import BTable from 'react-bootstrap/Table';

import { API } from 'src/Services/api';

interface IVideoRecordings {
  studentId: string;
  teacherId: string;
}

export const VideoRecordings = ({
  studentId,
  teacherId,
}: IVideoRecordings): JSX.Element => {
  return (
    <BTable striped bordered hover>
      <thead>
        <tr>
          <th>Participant #</th>
          <th>Participant Type</th>
          <th>file</th>
        </tr>
      </thead>
      <tbody>
        <tr key="student">
          <td>{studentId}</td>
          <td>Student</td>
          <td>
            <DownloadVideoButton videoId={studentId} />
          </td>
        </tr>
        <tr key="teacher">
          <td>{teacherId}</td>
          <td>Teacher</td>
          <td>
            <DownloadVideoButton videoId={teacherId} />
          </td>
        </tr>
      </tbody>
    </BTable>
  );
};

const DownloadVideoButton = ({ videoId }: { videoId: string }) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const handleClick = () => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), 1000);
    API.videos.getVideo(videoId);
  };

  return (
    <Button variant="link" onClick={handleClick} disabled={disabled}>
      Download
    </Button>
  );
};
