import axios, { AxiosError } from 'axios';
import fileDownload from 'js-file-download';

import { isProd, withDocker } from 'src/Config';
import { ISession } from 'src/Containers/Admin/SessionsContainer/SessionsTable';

import TokenService from './TokenService';

export interface AccountCreds {
  password: string;
  username: string;
}

interface SessionConfig {
  count: number;
  experiments: {
    gears: boolean;
    text: boolean;
    science: boolean;
  };
}

const headers = {
  'Content-Type': 'application/json',
};

const productionServer = 'https://stip.tc.columbia.edu';
const SOCKET_SERVER_ADDRESS = isProd
  ? productionServer
  : withDocker
  ? 'http://localhost:80'
  : 'http://localhost:8081';

const SERVER_ADDRESS = isProd
  ? `${productionServer}/api`
  : withDocker
  ? 'http://localhost:80/api'
  : 'http://localhost:8000';

export const API = {
  SERVER_ADDRESS,
  SOCKET_SERVER_ADDRESS,
  checkParticipant: async (participantId: string) => {
    try {
      const res = await axios.post(
        `${SERVER_ADDRESS}/participant`,
        { participantId },
        { headers },
      );
      return res.data;
    } catch (e) {
      return { error: 'Unable to contact server at this time.' };
    }
  },
  login: async (accountCreds: AccountCreds) => {
    try {
      const res = await axios.post(
        `${SERVER_ADDRESS}/admin/login`,
        accountCreds,
        { headers },
      );
      TokenService.saveAuthToken(res.data.token);
      return res.data;
    } catch (e) {
      if ((e as AxiosError).response!.status === 500) {
        return {
          error:
            'Unable to reach server. Please try your request in a few seconds',
        };
      } else return { error: 'Invalid username or password' };
    }
  },
  getParticipants: async () => {
    try {
      const res = await axios.get(`${SERVER_ADDRESS}/participants`, {
        headers: {
          authorization: `bearer ${TokenService.getAuthToken()}`,
        },
      });
      return res.data;
    } catch (e) {
      if (typeof e === 'object') {
        if ((e as AxiosError).response?.status === 500) {
          return {
            error:
              'Unable to reach server. Please try your request in a few seconds',
          };
        } else {
          if ((e as AxiosError).response?.status === 401) {
            return {
              error: 'Unauthorized',
            };
          }
        }
      } else return e;
    }
  },
  getRefreshToken: async () => {
    try {
      const res = await axios.post(`${SERVER_ADDRESS}/auth/refresh`, {
        headers: {
          authorization: `bearer ${TokenService.getAuthToken()}`,
        },
      });

      TokenService.saveAuthToken(res.data.token);
    } catch (e) {
      console.log('Problem getting refresh token', e);
    }
  },
  sessions: {
    getSessionsByAdmin: async () => {
      try {
        const res = await axios.get(`${SERVER_ADDRESS}/sessions/`, {
          headers: {
            authorization: `bearer ${TokenService.getAuthToken()}`,
          },
        });

        if (!res.data) {
          return [];
        }
        return res.data;
      } catch (e) {
        console.log('problem getting sessions');
      }
    },
    createSession: async (sessionConfig: SessionConfig) => {
      try {
        const res = await axios.post(
          `${SERVER_ADDRESS}/sessions/create`,
          { sessionConfig },
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e: unknown) {
        return { error: (e as Error)?.message };
      }
    },
    deleteSession: async (sessionId: string) => {
      try {
        const res = await axios.delete(
          `${SERVER_ADDRESS}/sessions/${sessionId}`,
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log(e);
        console.log('problem deleting session');
      }
    },
    updateSession: async (
      sessionId: string,
      updatedSession: Partial<ISession>,
    ) => {
      try {
        const res = await axios.patch(
          `${SERVER_ADDRESS}/sessions/${sessionId}`,
          updatedSession,
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log(e);
        console.log('problem updating session');
      }
    },
    processSession: async (sessionId: string) => {
      try {
        const res = await axios.post(
          `${SERVER_ADDRESS}/sessions/process/${sessionId}`,
          {},
          {
            headers: {
              length: 0,
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log(e);
        console.log('problem processing session');
      }
    },
  },

  session: {
    async getTimestamps(sessionId: string) {
      try {
        const res = await axios.get(
          `${SERVER_ADDRESS}/sessions/${sessionId}/timestamps`,
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log('problem getting timestamps');
      }
    },
    async getSurveyResponses(sessionId: string) {
      try {
        const res = await axios.get(
          `${SERVER_ADDRESS}/sessions/${sessionId}/survey_responses`,
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log('problem getting survey responses');
      }
    },
    async getGearResponses(sessionId: string) {
      try {
        const res = await axios.get(
          `${SERVER_ADDRESS}/sessions/${sessionId}/gear_responses`,
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log('problem getting gear responses');
      }
    },
    async getMCResponses(sessionId: string) {
      try {
        const res = await axios.get(
          `${SERVER_ADDRESS}/sessions/${sessionId}/mc_responses`,
          {
            headers: {
              authorization: `bearer ${TokenService.getAuthToken()}`,
            },
          },
        );
        return res.data;
      } catch (e) {
        console.log('problem getting multiple choice responses');
      }
    },
  },
  videos: {
    async getVideo(videoId: string) {
      try {
        const res = await axios.get(`${SERVER_ADDRESS}/videos/${videoId}`, {
          responseType: 'blob',
          headers: {
            authorization: `bearer ${TokenService.getAuthToken()}`,
          },
        });
        fileDownload(res.data, `${videoId}.mp4`);
        return res.data;
      } catch (e) {
        console.log('problem getting video');
      }
    },
  },
};
