import * as React from 'react';
import { logger } from 'src/Helpers';
import { useSocket } from './SocketContext';

type GearDirection = 'clockwise' | 'counter-clockwise' | 'locked';
export type GearType = 'open' | 'closed';

export interface MultipleChoiceQuestion {
  prompt: string;
  choices: string[];
  correctAnswer: string;
}

export interface TextQuestion {
  sections: string[];
  header: string;
  questions: MultipleChoiceQuestion[];
}

export interface ScienceQuestion {
  sections: string[];
  header: string;
}

export interface GearQuestion {
  imageUrl: string;
  prompt: string;
  answerOptions: GearDirection[];
  correctAnswer: GearDirection;
  type: GearType;
  gearCount: number;
}

export interface QuestionBank {
  shortOpen: GearQuestion[];
  longOpen: GearQuestion[];
  shortClosed: GearQuestion[];
  longClosed: GearQuestion[];
  textQuestions: TextQuestion[];
}

export type QuestionState = QuestionBank | null;

const NEED_QUESTIONS = 'NEED QUESTIONS';
const QUESTION_DATA = 'QUESTION DATA';

export const QuestionContext = React.createContext<QuestionState>(null);

export function QuestionProvider(props: { children: React.ReactNode }) {
  const [
    questionContext,
    updateQuestionContext,
  ] = React.useState<QuestionBank | null>(null);
  const socket = useSocket();

  const setQuestionContext = React.useCallback(
    (q: QuestionState) => {
      logger('was called');
      if (q === null) {
        console.log('have to refetch questions');
        socket.emit(NEED_QUESTIONS);
      }
      updateQuestionContext(q);
    },
    [socket],
  );

  React.useEffect(() => {
    socket.on(QUESTION_DATA, setQuestionContext);

    return () => {
      socket.off(QUESTION_DATA);
    };
  }, [socket, setQuestionContext]);

  return (
    <QuestionContext.Provider value={questionContext}>
      {props.children}
    </QuestionContext.Provider>
  );
}
